import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import { createHead } from '@vueuse/head'
import { Integrations } from '@sentry/tracing'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { createGtm } from '@gtm-support/vue-gtm'

import Mixpanel from '@/plugins/mixpanel'
import Helpscout from '@/plugins/helpscout'

import App from '@/App.vue'
import store from '@/store'
import router from '@/router'

import autoBlur from '@/directives/auto-blur'
import partyMode from '@/directives/party-mode-activator'

import '@/assets/styles/animations.styl'
import '@/assets/styles/global.styl'

// load some css that's better loaded globally than a million times as scoped
//  css (this also fixes bugs related to loading the same css rules more than
//  once while navigating around)
import '@/assets/styles/tag.styl'
import '@/assets/styles/forms.styl'
import '@/assets/styles/table.styl'
import '@/assets/styles/tooltip.styl'
import '@/assets/styles/overflow-menu.styl'

// detect chunk load errors and auto-refresh the browser if needed
//
// chunk load errors happen when a new build of the app is deployed but someone
//  has the old build still loaded up in their browser
router.onError((error) => {

  const regexp = /loading (.*?)? ?chunk (.+) failed/i

  if (!regexp.test(error.message)) return

  const chunkType = error.message.match(regexp)[1] || 'JS'
  const chunkName = error.message.match(regexp)[2]
  const chunkKey = `${chunkName}.${chunkType.toLowerCase()}`

  // as a safety measure to protect against infinite reloads, save the chunk
  //  name to sessionStorage and only reload the page if we haven't already
  //  seen that chunk fail to load
  const failedChunkLoads = JSON.parse(window.sessionStorage.getItem('failedChunkLoads') || '[]')

  if (failedChunkLoads.includes(chunkKey)) {
    // @NOTE: throwing an error here normally doesn't work - vue-router probably
    //  squelches errors thrown in onError() handlers... therefore they do not
    //  bubble up and Sentry can't detect it
    //
    // so let's just give the error a new context to be thrown in and return
    //  instead
    setTimeout(() => {
      throw new Error(`Loading ${chunkType} chunk ${chunkName} failed more than once, skipping auto-refresh to prevent infinite loop.`)
    })
    return
  }

  failedChunkLoads.push(chunkKey)
  window.sessionStorage.setItem('failedChunkLoads', JSON.stringify(failedChunkLoads))

  window.location.reload()

})

store.dispatch('app/INIT')
  .finally(() => {

    const app = createApp(App)

    app.use(store)
    app.use(router)
    app.use(createHead())

    if (process.env.VUE_APP_SENTRY_DSN) {
      Sentry.init({
        app,
        logErrors: true,
        environment: process.env.VUE_APP_ENV,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: [
              /^\//,
              'localhost',
              process.env.VUE_APP_DISPATCH_API_BASE_URL.replace(/^https?:\/\//, ''),
            ],
          }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      })
    }

    if (process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID) {
      app.use(createGtm({
        vueRouter: router,
        id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID,
      }))
    }

    if (process.env.VUE_APP_RECAPTCHA_SITE_KEY) {
      app.use(VueReCaptcha, {
        siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
        loaderOptions: {
          autoHideBadge: true,
        },
      })
    }

    app.use(Helpscout, {
      token: process.env.VUE_APP_HELPSCOUT_BEACON_ID,
    })

    app.use(Mixpanel, {
      token: process.env.VUE_APP_MIXPANEL_TOKEN,
      esprezzoIoToken: process.env.VUE_APP_MIXPANEL_TOKEN_ESPREZZO_IO,
      config: {
        api_host: process.env.VUE_APP_MIXPANEL_PROXY_URL,
        debug: process.env.VUE_APP_ENV === 'development',
      },
    })

    app.directive(autoBlur.name, autoBlur.directive)
    app.directive(partyMode.name, partyMode.directive)

    app.mount('#app')

  })
