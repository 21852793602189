import { markRaw, defineAsyncComponent } from 'vue'

import ErrorModal from '@/components/modals/ErrorModal.vue'
import LoadingIcon from '@/components/utils/LoadingIcon.vue'

export default {

  SET_IS_MODAL_OPEN(currentState, newValue) {
    currentState.isModalOpen = newValue
  },

  SET_ALLOW_MODAL_CLOSE(currentState, newValue) {
    currentState.allowModalClose = newValue
  },

  SET_CURRENT_MODAL_MODE(currentState, mode) {
    currentState.currentModal.mode = mode
  },

  SET_CURRENT_MODAL(currentState, { name, props, mode = 'normal', onClose }) {

    currentState.currentModal.mode = mode
    currentState.currentModal.name = name
    currentState.currentModal.props = props
    currentState.currentModal.onClose = onClose

    currentState.currentModal.component = markRaw(defineAsyncComponent({

      // @NOTE: for some reason, these fallback components do not seem to work
      //  anymore in Vue 3... I will leave them here as-is in the hopes of
      //  future fixes
      error: ErrorModal,
      loading: LoadingIcon,

      // @NOTE: for some reason babel-eslint doesn't like template strings
      //  inside import() statements... the easiest thing to do is to just use
      //  string concatenation instead
      //
      // see: https://github.com/babel/babel-eslint/issues/799
      // see: https://stackoverflow.com/questions/48391913/eslint-error-cannot-read-property-range-of-null
      //
      // eslint-disable-next-line
      loader: () => { return import('@/components/modals/' + name + '.vue') },
    }))

  },

  CLEAR_CURRENT_MODAL(currentState) {
    currentState.currentModal.name = null
    currentState.currentModal.props = null
    currentState.currentModal.mode = 'normal'
    currentState.currentModal.onClose = null
    currentState.currentModal.component = null
  },

}
