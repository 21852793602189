<template>
  <div class="banner" v-if="isAPIReadOnly">
    <div class="alert warning">
      We’re currently conducting maintenance; some features have been temporarily disabled. Please check back later.
    </div>
  </div>
  <!-- <div class="banner error">
    <div class="alert error">
      <strong>Degraded performance:</strong>
      Some users are not receiving Dispatch alerts. We are investigating the issue.
    </div>
  </div> -->
  <router-view />
  <ToastContainer />
  <ModalContainer />
</template>

<script>

  import { mapState } from 'vuex'
  import isMobile from 'is-mobile'
  import { ref, computed } from 'vue'
  import { useHead } from '@vueuse/head'

  import ToastContainer from '@/components/utils/ToastContainer.vue'
  import ModalContainer from '@/components/modals/_ModalContainer.vue'

  export default {
    inject: [
      '$mixpanel',
      '$helpscout',
    ],
    components: {
      ToastContainer,
      ModalContainer,
    },
    setup() {

      const pageName = ref(null)
      const description = 'Esprezzo Dispatch is a no-code platform for building powerful Web3 experiences.'

      useHead({
        title: computed(() => {
          return pageName.value ? `${pageName.value} | Esprezzo Dispatch` : 'Esprezzo Dispatch'
        }),
        meta: [
          { hid: 'description', name: 'description', content: description },

          { hid: 'og:type', name: 'og:type', content: 'website' },
          { hid: 'og:title', name: 'og:title', content: 'Esprezzo Dispatch' },
          { hid: 'og:image:width', name: 'og:image:width', content: '2400' },
          { hid: 'og:image:height', name: 'og:image:height', content: '1260' },
          { hid: 'og:description', name: 'og:description', content: description },
          { hid: 'og:url', name: 'og:url', content: process.env.VUE_APP_DISPATCH_URL },
          { hid: 'og:image', name: 'og:image', content: 'https://content.esprezzo.io/dispatch/open-graph-image.jpg' },
          { hid: 'og:image:alt', name: 'og:image:alt', content: 'Esprezzo Dispatch: a no-code platform for building powerful Web3 experiences' },

        ],
      })

      return {
        pageName,
      }

    },
    computed: {
      ...mapState('app', ['isAPIReadOnly', 'isInMaintenanceMode']),
      ...mapState('user', ['authTokenExpiry', 'refreshToken', 'refreshTokenExpiry']),
    },
    watch: {
      $route(to) {

        if (!to.meta.doNotTrackPageView) {
          this.$mixpanel.onReady((mixpanel) => {

            this.pageName = this.$route.name.replace(/(\S)([A-Z])/g, '$1 $2')

            const registerData = {
              application: 'dispatch',

              pageName: this.pageName,
              referrer: document.referrer,
              platform: isMobile() ? 'mobile' : 'web',

              utmTerm: to.query.utm_term,
              utmSource: to.query.utm_source,
              utmMedium: to.query.utm_medium,
              utmContent: to.query.utm_content,
              utmCampaign: to.query.utm_campaign,
            }

            const eventData = {
              query: to.query,
              routeName: to.name,
              name: this.pageName,
              title: document.title,
              pageName: this.pageName,
              url: window.location.href,
              hash: window.location.hash,
              referrer: document.referrer,
              path: window.location.pathname,
              search: window.location.search,
            }

            mixpanel.register(registerData)
            mixpanel['esprezzo.io'].register(registerData)

            // both of these versions of a page view event are useful for
            // analytics (and specificly requested by J)
            mixpanel.track(`${this.pageName} Page Viewed`, eventData)
            mixpanel.track_pageview(eventData)

          })

        }

        if (this.$store.state.modals.isModalOpen) {
          this.$store.dispatch('modals/CLOSE_MODAL')
        }

      },
      authTokenExpiry() {
        this.setAuthTokenExpiryTimeout()
      },
    },
    data() {
      return {
        authTokenCheckTimeoutId: null,
      }
    },
    created() {

      if (this.isInMaintenanceMode) {
        this.$router.push({ name: 'Maintenance' })
        return
      }

      this.checkAuthTokenExpiry()
      this.setAuthTokenExpiryTimeout()
      document.addEventListener('visibilitychange', this.onVisibilityChange)

      const helpscoutEventMap = {
        open: 'Opened',
        close: 'Closed',
        search: 'Docs Searched',
        'email-sent': 'Email Sent',
        'chat-started': 'Chat Started',
        'message-closed': 'Message Closed',
        'message-clicked': 'Message Clicked',
        'message-triggered': 'Message Shown',
        'article-viewed': 'Docs Article Viewed',
      }

      this.$helpscout.onReady((helpscout) => {
        Object.keys(helpscoutEventMap).forEach((eventName) => {
          helpscout('on', eventName, () => {
            this.$mixpanel.onReady((mixpanel) => {
              mixpanel.track(`Help Scout Beacon ${helpscoutEventMap[eventName]}`)
            })
          })
        })
      })

    },
    beforeUnmount() {
      clearTimeout(this.authTokenCheckTimeoutId)
      document.removeEventListener('visibilitychange', this.onVisibilityChange)
    },
    methods: {
      onVisibilityChange() {
        clearTimeout(this.authTokenCheckTimeoutId)
        if (document.visibilityState !== 'visible') return
        this.setAuthTokenExpiryTimeout()
      },
      setAuthTokenExpiryTimeout() {

        clearTimeout(this.authTokenCheckTimeoutId)

        const expiryString = this.authTokenExpiry

        const now = Date.now()
        const expiry = new Date(expiryString)

        if (!expiryString) return
        if (expiry.toString() === 'Invalid Date') return

        const delay = Math.max(0, expiry.getTime() - now)

        this.authTokenCheckTimeoutId = setTimeout(() => {
          this.checkAuthTokenExpiry()
        }, delay)

      },
      checkAuthTokenExpiry() {

        const expiryString = this.authTokenExpiry

        const now = Date.now()
        const expiry = new Date(expiryString)

        // @NOTE: we *could* check if document.visibilityState === 'visible'
        //  here, but really there's no harm in doing the check when
        //  document.visibilityState === 'hidden' as well
        //
        // @NOTE: also, the expiryString check might seem redundant, but it's
        //  necessary since new Date(null) is a valid date (the unix epoch)
        if (!expiryString) return null
        if (expiry.toString() === 'Invalid Date') return null
        if (expiry.getTime() > now) return null

        if (
          !this.refreshToken
          || !this.refreshTokenExpiry
          || new Date(this.refreshTokenExpiry).getTime() <= now
        ) {
          return this.$store.dispatch('app/LOGOUT', { force: true })
        }

        return this.$store.state.api.dispatch.put('/session', { refreshToken: this.refreshToken })
          .then((response) => {

            const newAuthTokenData = {
              newAuthToken: response.data.accessToken,
              newAuthTokenExpiry: response.data.expiresAt,
              newRefreshToken: response.data.refreshToken,
              newRefreshTokenExpiry: this.refreshTokenExpiry,
            }

            return this.$store.dispatch('user/UPDATE_AUTH_TOKEN', newAuthTokenData)

          })
          .catch((response) => {
            // do nothing
          })

      },
    },
  }

</script>

<style lang="stylus">

  // @NOTE: we can't use w-screen / 100vw because when scrollbars are enabled on
  //  osx, it takes away a little bit of page width but 100vw remains the same
  //  (meaning there's a bit of annoying and unnecessary horizontal scrolling)
  #app
    @apply w-full
    @apply h-screen

    min-width: 100%
    min-height: 100vh

  .banner
    @apply z-10
    @apply top-0
    @apply sticky
    @apply w-full
    @apply bg-warning-100
    @apply border-warning-500

    @apply flex
    @apply justify-center

    &+.banner
      @apply border-t

    &.error
      @apply bg-danger-100
      @apply border-danger-300

    .alert
      @apply my-0
      @apply border-none
      @apply shadow-none

</style>
