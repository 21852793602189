<template>
  <div ref="backdrop" class="modal-container" :class="{ 'show': isModalOpen }" @click="closeModal">
    <div tabindex="0" ref="modal" class="modal" :class="[currentModal.mode]" @click.stop>
      <button class="link gray close-button" @click="closeModal" v-if="allowModalClose">
        <CloseIcon />
      </button>
      <component :is="currentModal.component" v-bind="currentModal.props" />
    </div>
  </div>
</template>

<script>

  import { mapState } from 'vuex'

  import CloseIcon from '@/assets/icons/close.svg'

  import EventBus from '@/components/utils/EventBus.vue'

  export default {
    components: {
      CloseIcon,
    },
    computed: {
      ...mapState('modals', ['isModalOpen', 'currentModal', 'allowModalClose']),
    },
    watch: {
      // prevent page scrolling when modals are open
      isModalOpen(newValue, oldValue) {
        if (newValue) {
          this.scrollToTop()
          this.$refs.modal.focus()
          document.body.style.overflow = 'hidden'
          document.addEventListener('keydown', this.onKeyDown)
        } else {
          document.body.style.overflow = 'visible'
          document.removeEventListener('keydown', this.onKeyDown)
        }
      },
    },
    mounted() {
      EventBus.$on('modal:scroll-to-top', this.scrollToTop)
    },
    beforeUnmount() {
      EventBus.$off('modal:scroll-to-top', this.scrollToTop)
    },
    methods: {
      scrollToTop() {
        if (this.$refs.backdrop) this.$refs.backdrop.scrollTop = 0
      },
      closeModal() {
        if (!this.allowModalClose) return
        this.$store.dispatch('modals/CLOSE_MODAL')
      },
      onKeyDown(event) {
        if (event.target && (event.target.tagName === 'TEXTAREA' || event.target.tagName === 'INPUT')) return
        if (event.code !== 'Escape') return
        this.closeModal()
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .modal-container
    @apply p-8
    @apply z-50
    @apply w-full
    @apply h-full
    @apply opacity-0
    @apply overflow-scroll
    @apply transition-opacity
    @apply pointer-events-none

    @apply fixed
    @apply top-0
    @apply left-0

    @apply flex
    @apply items-start
    @apply justify-center

    @apply bg-purple-1000
    @apply bg-opacity-60

    +breakpoint(sm)
      @apply pt-32

    &.show
      @apply opacity-100
      @apply pointer-events-auto

      .modal
        @apply transform-none

  .modal
    @apply p-6
    @apply z-50
    @apply pt-10
    @apply w-full
    @apply relative
    @apply bg-white
    @apply rounded-sm
    @apply transition-transform

    transform: translate(0, -3rem)

    // the modal doesn't actually need to appear focused, since focusing on the
    //  div is really just used to bring focus "into" the modal
    &:focus-visible
      outline: 0

    +breakpoint(sm)
      @apply p-12

      max-width: 37.5rem

    &.wide
      +breakpoint(sm)
        max-width: 50rem

  .close-button
    @apply w-4
    @apply h-4
    @apply text-gray-600

    @apply top-4
    @apply right-4
    @apply absolute

    svg
      @apply w-full
      @apply h-full

</style>
