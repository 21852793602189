export default () => {
  return {
    toasts: [],
    types: [
      'info',
      'error',
      'warning',
      'success',
    ],
  }
}
