export const getABIEntrySignaturePieces = (abiEntrySignature) => {
  const [, abiEntryName, abiEntryArguments] = abiEntrySignature.match(/(.*?)\((.*)\)/)
  return { abiEntrySignature, abiEntryName, abiEntryArguments }
}

// @NOTE: this needs to be synchronous (i.e. not return a Promise) since this
//  method is used in the validate() functions for the proxyContractABIJSON and
//  implementationContractABIJSON form fields in createUserContractForm
export const parseABI = (value) => {

  let abi = null

  const events = []
  const functions = []

  try {

    abi = JSON.parse(value)
    abi = abi.abi || abi.ABI || abi

    if (typeof abi === 'string') {
      abi = JSON.parse(abi)
    }

  } catch (error) {
    throw new Error('JSON syntax error. Please make sure your JSON is properly formatted and try again.')
  }

  if (!Array.isArray(abi)) {
    throw new Error('This JSON doesn\'t appear to contain an array of contract ABI entries.')
  }

  abi.forEach((abiEntry) => {

    // make sure the entry is an object
    if (typeof abiEntry !== 'object') return null

    // make sure the entry has an "inputs" array, as well as "name"
    //  and "type" strings
    if (
      !Array.isArray(abiEntry.inputs)
      || typeof abiEntry.name !== 'string'
      || typeof abiEntry.type !== 'string'
    ) return null

    // if the entry is an event, check for the "anonymous" boolean, which blows
    //  up the ABI parsing flow on the backend if not present for events
    if (
      abiEntry.type === 'event'
      && typeof abiEntry.anonymous === 'boolean'
    ) return events.push(abiEntry)

    // if the entry a function, we need to make sure it's not "view"
    //  or "pure" since those do not generate transactions that we can
    //  detect in block logs
    //
    // also check for the "outputs" array, which blows up the ABI parsing
    //  flow on the backend if not present for functions
    if (
      abiEntry.type === 'function'
      && Array.isArray(abiEntry.outputs)
      && abiEntry.stateMutability !== 'view'
      && abiEntry.stateMutability !== 'pure'
    ) return functions.push(abiEntry)

    return null

  })

  if (events.length === 0 && functions.length === 0) {
    throw new Error('This contract ABI doesn\'t appear to contain any events or non-view/non-pure functions.')
  }

  return { abi, events, functions }

}

export default () => {

  return {
    parseABI,
    getABIEntrySignaturePieces,
  }

}
