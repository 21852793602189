// for displaying / parsing "currency fields" using local number separators
const localeNumberString = (1111.11).toLocaleString()
const [thousandsSeparator, decimalSeparator] = localeNumberString.match(/(,|\.)/g)
const disallowedCurrencyCharactersRegex = new RegExp(`[^\\d${decimalSeparator}${thousandsSeparator}]`, 'g')

export default () => {
  return {
    actionOptions: [],
    triggerOptions: [],
    networkOptions: [],
    patchTemplates: [],
    contractOptions: [],

    actionOptionsIdMap: {},
    triggerOptionsIdMap: {},
    networkOptionsIdMap: {},
    contractOptionsIdMap: {},

    stripePlansIdMap: {},
    stripeAddonsIdMap: {},
    stripePricesIdMap: {},

    stripePlansSlugMap: {},
    stripeAddonsSlugMap: {},
    stripePricesSlugMap: {},

    actionOptionsSlugMap: {},
    patchTemplatesSlugMap: {},
    actionSettingsSlugMap: {},
    triggerOptionsSlugMap: {},
    networkOptionsSlugMap: {},
    triggerSettingsSlugMap: {},
    triggerNetworkOptionsSlugMap: {},
    networkContractOptionsByTypeSlugMap: {},

    isBootstrapDataLoading: true,

    // @NOTE: this was originally supposed to be read dynamically from the API
    //  but it was decided that was "too much work" so now it's just manually
    //  toggled via deploys ¯\_(ツ)_/¯
    isAPIReadOnly: false,
    isInMaintenanceMode: false,

    dispatchBotName: process.env.VUE_APP_BOT_NAME,
    telegramBotUrl: `https://t.me/${process.env.VUE_APP_TELEGRAM_BOT_USERNAME}`,
    discordBotGuildUrl: `https://discord.com/api/oauth2/authorize?response_type=code&redirect_uri=${encodeURIComponent(process.env.VUE_APP_DISCORD_BOT_GUILD_REDIRECT_URL)}&client_id=${process.env.VUE_APP_DISCORD_BOT_CLIENT_ID}&permissions=${process.env.VUE_APP_DISCORD_BOT_PERMISSIONS}`,
    discordBotDirectMessageUrl: `https://discord.com/api/oauth2/authorize?response_type=code&redirect_uri=${encodeURIComponent(process.env.VUE_APP_DISCORD_BOT_DIRECT_MESSAGE_REDIRECT_URL)}&client_id=${process.env.VUE_APP_DISCORD_BOT_CLIENT_ID}&permissions=${process.env.VUE_APP_DISCORD_BOT_PERMISSIONS}`,

    decimalSeparator,
    localeNumberString,
    thousandsSeparator,
    disallowedCurrencyCharactersRegex,
    convertStringToNumber(currencyString) {

      if (typeof currencyString !== 'string') {
        throw new Error(`convertStringToNumber called with non-string value: ${currencyString}`)
      }

      const decimalSeparatorRegex = new RegExp(`[${decimalSeparator}]`, 'g')
      const thousandsSeparatorRegex = new RegExp(`[${thousandsSeparator}]`, 'g')

      const numberString = currencyString
        .replace(disallowedCurrencyCharactersRegex, '')
        .replace(thousandsSeparatorRegex, '')
        .replace(decimalSeparatorRegex, '.')

      return Number.parseFloat(numberString)

    },
  }
}
