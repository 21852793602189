import { getDefaultState } from './state'

const resetFormField = (currentState, { formName, fieldName }) => {

  const defaultFormState = getDefaultState()[formName]

  currentState[formName].fields[fieldName].error = null
  currentState[formName].fields[fieldName].isDirty = false

  currentState[formName].fields[fieldName].note = defaultFormState.fields[fieldName].note
  currentState[formName].fields[fieldName].value = defaultFormState.fields[fieldName].value
  currentState[formName].fields[fieldName].label = defaultFormState.fields[fieldName].label
  currentState[formName].fields[fieldName].disabled = defaultFormState.fields[fieldName].disabled
  currentState[formName].fields[fieldName].required = defaultFormState.fields[fieldName].required
  currentState[formName].fields[fieldName].placeholder = defaultFormState.fields[fieldName].placeholder
  currentState[formName].fields[fieldName].displayValue = defaultFormState.fields[fieldName].displayValue
  currentState[formName].fields[fieldName].invalidMultiValues = defaultFormState.fields[fieldName].invalidMultiValues

}

export default {

  SET_FIELD_VALUE(currentState, { formName, fieldName, newValue }) {
    currentState[formName].fields[fieldName].value = newValue
  },

  SET_FIELD_DISPLAY_VALUE(currentState, { formName, fieldName, newValue }) {
    currentState[formName].fields[fieldName].displayValue = newValue
  },

  SET_FIELD_INVALID_MULTI_VALUES(currentState, { formName, fieldName, newValue }) {
    currentState[formName].fields[fieldName].invalidMultiValues = newValue
  },

  SET_FIELD_LABEL(currentState, { formName, fieldName, newValue }) {
    currentState[formName].fields[fieldName].label = newValue
  },

  SET_FIELD_PLACEHOLDER(currentState, { formName, fieldName, newValue }) {
    currentState[formName].fields[fieldName].placeholder = newValue
  },

  SET_FIELD_NOTE(currentState, { formName, fieldName, newValue }) {
    currentState[formName].fields[fieldName].note = newValue
  },

  SET_FIELD_IS_DIRTY(currentState, { formName, fieldName, newValue }) {
    currentState[formName].fields[fieldName].isDirty = newValue
  },

  SET_FIELD_IS_LOADING(currentState, { formName, fieldName, newValue }) {
    currentState[formName].fields[fieldName].isLoading = newValue
  },

  SET_FIELD_ERROR(currentState, { formName, fieldName, newError }) {
    currentState[formName].fields[fieldName].error = newError
  },

  SET_FIELD_REQUIRED(currentState, { formName, fieldName, newValue }) {
    currentState[formName].fields[fieldName].required = newValue
  },

  SET_FIELD_DISABLED(currentState, { formName, fieldName, newValue }) {
    currentState[formName].fields[fieldName].disabled = newValue
  },

  SET_FIELD_OPTIONS(currentState, { formName, fieldName, newOptions }) {
    currentState[formName].fields[fieldName].options = newOptions
  },

  SET_FORM_SUBMIT_ERRORS(currentState, { formName, newErrors }) {
    currentState[formName].submitErrors = newErrors
  },

  SET_IS_LOADING(currentState, { formName, newValue }) {
    currentState[formName].isLoading = newValue
  },

  SET_IS_SUBMITTED(currentState, { formName, newValue }) {
    currentState[formName].isSubmitted = newValue
  },

  SET_FORM_TEMPLATE(currentState, { formName, newTemplate }) {
    currentState[formName].template = newTemplate
  },

  SET_SUBMIT_METHOD(currentState, { formName, newSubmitMethod }) {
    currentState[formName].submitMethod = newSubmitMethod
  },

  SET_SUBMIT_ENDPOINT(currentState, { formName, newSubmitEndpoint }) {
    currentState[formName].submitEndpoint = newSubmitEndpoint
  },

  RESET_FORM_FIELD(currentState, { formName, fieldName }) {
    resetFormField(currentState, { formName, fieldName })
  },

  RESET_FORM(currentState, formName) {

    const defaultFormState = getDefaultState()[formName]

    currentState[formName].isLoading = false
    currentState[formName].isSubmitted = false
    currentState[formName].submitErrors = []

    if (currentState[formName].template) {
      currentState[formName].template = defaultFormState.template
    }

    Object.keys(currentState[formName].fields).forEach((fieldName) => {

      const field = currentState[formName].fields[fieldName]

      resetFormField(currentState, { formName, fieldName })

      // if this is a timerange field, set the highest available timerange as
      //  the default selected option
      if (field.metaType === 'timerange') {
        field.options.forEach((option) => {
          if (option.disabled || option.value < field.value) return
          currentState[formName].fields[fieldName].value = option.value
        })
      }

    })

  },

}
