export default {

  SET_PATCH_TEMPLATES(currentState, newPatchTemplates) {
    currentState.patchTemplates = newPatchTemplates
  },

  SET_ACTION_OPTIONS(currentState, newOptions) {
    currentState.actionOptions = newOptions
  },

  SET_TRIGGER_OPTIONS(currentState, newOptions) {
    currentState.triggerOptions = newOptions
  },

  SET_NETWORK_OPTIONS(currentState, newOptions) {
    currentState.networkOptions = newOptions
  },

  SET_CONTRACT_OPTIONS(currentState, newOptions) {
    currentState.contractOptions = newOptions
  },

  SET_OPTION_MAPS(currentState, newMaps) {
    Object.keys(newMaps).forEach((mapName) => {
      currentState[mapName] = newMaps[mapName]
    })
  },

  SET_IS_API_READ_ONLY(currentState, newValue) {
    currentState.isAPIReadOnly = newValue
  },

  SET_IS_BOOTSTRAP_DATA_LOADING(currentState, newValue) {
    currentState.isBootstrapDataLoading = newValue
  },

}
