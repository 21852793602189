export default {

  name: 'party-mode-activator',

  directive: {

    beforeUnmount($element) {
      if (typeof $element.partyModeClickListener !== 'function') return
      $element.removeEventListener('click', $element.partyModeClickListener)
    },

    beforeMount($element) {

      let clickCount = 0
      let timeoutId = null
      let isPartyModeActivated = false

      $element.partyModeClickListener = () => {

        clickCount += 1
        clearTimeout(timeoutId)

        if (clickCount === 10) {

          clickCount = 0
          isPartyModeActivated = !isPartyModeActivated

          // @NOTE: for whatever reason, making the default target the body tag
          // has a weird side affect of breaking the Helpscount widget's
          // positioning, so instead we'll just target the app container
          const $explicitTargets = document.querySelectorAll('[party-mode-target]')
          const $targets = $explicitTargets.length > 0 ? $explicitTargets : [document.querySelector('#app')]

          if (isPartyModeActivated) {
            $targets.forEach(($target) => {
              $target.classList.add('party-mode')
            })
          } else {
            $targets.forEach(($target) => {
              $target.classList.remove('party-mode')
            })
          }

        } else {
          timeoutId = setTimeout(() => {
            clickCount = 0
          }, 500)
        }

      }

      $element.addEventListener('click', $element.partyModeClickListener)

    },

  },

}
