<template>
  <div class="loading-icon" :class="[size, type]"></div>
</template>

<script>

  export default {
    props: {
      size: {
        type: String,
        default: 'large',
      },
      type: {
        type: String,
        default: 'light',
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .loading-icon
    @apply w-4
    @apply h-4
    @apply animate-spin
    @apply rounded-full
    @apply inline-block

    @apply border-2
    @apply border-primary-200
    border-top-color: theme('colors.primary.400')

    &.medium
      @apply w-8
      @apply h-8
      @apply border-4

    &.large
      @apply w-16
      @apply h-16
      @apply border-8

    &.dark
      @apply border-purple-900
      border-top-color: theme('colors.purple.400')

</style>
