export default () => {
  return {
    isModalOpen: false,
    allowModalClose: true,
    currentModal: {
      mode: 'normal', // "normal" or "wide"
      component: null,
      onClose: null,
      props: null,
      name: null,
    },
  }
}
