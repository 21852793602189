export default {
  SET_DISPATCH_AUTH_TOKEN(currentState, newAuthToken) {
    if (newAuthToken) {
      currentState.admin.defaults.headers.common.Authorization = `Bearer ${newAuthToken}`
      currentState.dispatch.defaults.headers.common.Authorization = `Bearer ${newAuthToken}`
    } else {
      delete currentState.admin.defaults.headers.common.Authorization
      delete currentState.dispatch.defaults.headers.common.Authorization
    }
  },
}
