<template>
  <BasicModal title="An Error Has Occurred">
    <p>
      {{ errorMessage }}
    </p>
  </BasicModal>
</template>

<script>

  import BasicModal from '@/components/modals/_BasicModal.vue'

  export default {
    components: {
      BasicModal,
    },
    props: {
      errorMessage: {
        type: String,
        default: 'Please try again later.',
      },
    },
  }

</script>

<style lang="stylus" scoped>

</style>
