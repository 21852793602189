<template>
  <div />
</template>

<script>

  export default {
    created() {
      this.$router.replace(this.$store.getters['user/isAuthenticated'] ? '/dashboard' : '/login')
    },
  }

</script>

<style lang="stylus" scoped>

</style>
