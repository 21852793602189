<script>

  // see: https://v3.vuejs.org/guide/migration/events-api.html#_3-x-update

  import emitter from 'tiny-emitter/instance'

  export default {
    $on: emitter.on,
    $off: emitter.off,
    $once: emitter.once,
    $emit: emitter.emit,
  }

</script>
