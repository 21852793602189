export default {

  OPEN_MODAL({ state, commit }, { name, props, mode, onClose }) {
    commit('SET_CURRENT_MODAL', { name, props, mode, onClose })
    commit('SET_IS_MODAL_OPEN', true)
  },

  CLOSE_MODAL({ state, dispatch, commit }) {

    if (!state.isModalOpen) return

    if (typeof state.currentModal.onClose === 'function') {
      state.currentModal.onClose()
    }

    commit('SET_IS_MODAL_OPEN', false)
    commit('SET_ALLOW_MODAL_CLOSE', true)

    // delay the clearing of the modal component so it doesn't dissapear until
    //  the "modal closing" animation finishes
    setTimeout(() => {
      commit('CLEAR_CURRENT_MODAL', false)
    }, 200)

  },

}
