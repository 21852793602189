import { titleCase } from 'title-case'

import {
  format as formatDate,
  differenceInCalendarDays,
} from 'date-fns'

const isToday = (date) => {

  const now = new Date()

  return (
    now.getUTCDate() === date.getUTCDate()
    && now.getUTCMonth() === date.getUTCMonth()
    && now.getUTCFullYear() === date.getUTCFullYear()
  )

}

export default {
  isAuthenticated(state) {
    return state.authToken !== null
  },
  isAdmin(state) {
    return !!state.user && state.user.role === 'administrator'
  },
  isOnFreePlan(state) {
    return !!state.userPlan && state.userPlan.slug === 'free'
  },
  isPPAEnabled(state, getters) {
    if (getters.isOnFreePlan) return false
    if (!state.userPlan || !state.userPlan.apiRecord) return false
    return !!state.userPlan.apiRecord.overagesAllowed
  },
  ppaCostEstimate(state, getters) {
    if (getters.isOnFreePlan) return 0
    if (!getters.actionsOverPlanLimit) return 0
    return getters.actionsOverPlanLimit * 0.02
  },
  payPeriod(state, getters) {
    if (
      getters.isOnFreePlan
      || !state.userPlan
      || !state.userPlan.billingInterval
    ) return null
    return titleCase(`${state.userPlan.billingInterval}ly`)
  },
  usageCycleEndsAt(state) {

    if (!state.userPlan || !state.userPlan.apiRecord.periodEndsAt) return null

    const date = new Date(state.userPlan.apiRecord.periodEndsAt)

    return isToday(date)
      ? formatDate(date, 'MMM d, yyyy \'at\' p')
      : formatDate(date, 'MMM d, yyyy')

  },
  usageCycleStartsAt(state) {
    if (!state.userPlan || !state.userPlan.apiRecord.periodStartsAt) return null
    return formatDate(new Date(state.userPlan.apiRecord.periodStartsAt), 'MMM d, yyyy')
  },
  billingPeriodEndsAt(state) {

    if (!state.userPlan || !state.userPlan.subscriptionPeriodEndsAt) return null

    const date = new Date(state.userPlan.subscriptionPeriodEndsAt)

    return isToday(date)
      ? formatDate(date, 'MMM d, yyyy \'at\' p')
      : formatDate(date, 'MMM d, yyyy')

  },
  nextSubscriptionBillingAt(state, getters) {
    if (!state.userPlan || !state.userPlan.subscriptionPeriodEndsAt) return null
    if (state.userPlan.stripeSubscription && !!state.userPlan.stripeSubscription.cancelAtPeriodEnd) return null
    return getters.billingPeriodEndsAt
  },
  failedPaymentGracePeriodEndsAt(state) {
    if (
      !state.userPlan
      || !state.userPlan.subscriptionPeriodEndsAt
    ) return null
    return formatDate(new Date(
      state.userPlan.subscriptionPeriodEndsAt.getFullYear(),
      state.userPlan.subscriptionPeriodEndsAt.getMonth(),
      state.userPlan.subscriptionPeriodEndsAt.getDate() + 14,
    ), 'MMM d, yyyy')
  },
  ppaActivatedAt(state, getters) {
    if (getters.isOnFreePlan) return false
    if (!state.userPlan || !state.userPlan.apiRecord || !state.userPlan.apiRecord.overagesAllowedAt) return false
    return formatDate(new Date(state.userPlan.apiRecord.overagesAllowedAt), 'MMM d, yyyy')
  },
  actionsRemaining(state) {
    // if (!state.userPlan || !state.userPlanStats) return 0
    // return Math.max(0, state.userPlan.actionEventLimit - state.userPlanStats.usage)
    if (!state.userPlanStats) return 0
    return state.userPlanStats.credits
  },
  actionsOverPlanLimit(state) {
    // if (!state.userPlan || !state.userPlanStats) return 0
    // return Math.max(0, state.userPlanStats.usage - state.userPlan.actionEventLimit)
    if (!state.userPlanStats) return 0
    return state.userPlanStats.overages
  },
  daysUntilUsageCycleReset(state) {
    if (!state.userPlan || !state.userPlan.apiRecord.periodEndsAt) return 0
    return differenceInCalendarDays(new Date(state.userPlan.apiRecord.periodEndsAt), Date.now())
  },
  actionsUsedPercentage(state) {
    if (!state.userPlan || !state.userPlanStats) return 0
    return Math.round((state.userPlanStats.usage / state.userPlan.actionEventLimit) * 100)
  },
  isNearActionLimit(state) {
    if (!state.userPlan || !state.userPlanStats) return false
    return state.userPlanStats.usage >= Math.floor(state.userPlan.actionEventLimit * 0.8)
  },
  isOverActionLimit(state) {
    if (!state.userPlan || !state.userPlanStats) return false
    return state.userPlanStats.usage >= state.userPlan.actionEventLimit
  },
  arePatchesPseudoPaused(state, getters) {
    return getters.isOverActionLimit && !getters.isPPAEnabled
  },
  shouldShowActionLimitWarnings(state, getters) {
    if (!state.userPlan) return false
    if (getters.isPPAEnabled) return false
    if (getters.isOverActionLimit) return false
    return getters.isNearActionLimit
  },
  shouldShowActionLimitErrors(state, getters) {
    if (!state.userPlan) return false
    if (getters.isPPAEnabled) return false
    return getters.isOverActionLimit
  },
  shouldShowFailedPaymentWarnings(state, getters) {
    if (!state.userPlan || !state.userPlan.stripeSubscription) return false
    return state.userPlan.stripeSubscription.status === 'past_due'
  },
  shouldShowFailedPaymentErrors(state, getters) {
    if (
      !state.userPlan
      || !state.userPlan.canceledStripeSubscription
      || !state.userPlan.canceledStripeSubscription.cancellationDetails
    ) return false
    return state.userPlan.canceledStripeSubscription.cancellationDetails.reason === 'payment_failed'
  },
  willPPADisable(state, getters) {
    if (!getters.isPPAEnabled) return false
    if (!getters.willSubscriptionCancel) return false
    return getters.isPPAEnabled && getters.willSubscriptionCancel
  },
  willSubscriptionUpgade(state, getters) {
    if (!getters.upcomingSubscriptionStripeProduct) return false
    return Number.parseInt(getters.upcomingSubscriptionStripeProduct.metadata.planTier, 10) > state.userPlan.tier
  },
  willSubscriptionDowngrade(state, getters) {
    if (!getters.upcomingSubscriptionStripeProduct) return false
    return Number.parseInt(getters.upcomingSubscriptionStripeProduct.metadata.planTier, 10) < state.userPlan.tier
  },
  willSubscriptionCancel(state, getters) {
    if (!getters.upcomingSubscriptionStripeProduct) return false
    return getters.upcomingSubscriptionStripeProduct.metadata.productSlug === 'free'
  },
  // @NOTE: this is used to know whether the user explictly set their plan to
  //  cancel or it's set to cancel for some other reason (such as a past due
  //  invoice)
  willSubscriptionCancelIntentionally(state, getters) {
    if (!state.userPlan || !getters.willSubscriptionCancel) return false
    return getters.willSubscriptionCancel && state.userPlan.stripeSubscription.cancelAtPeriodEnd
  },
  upcomingSubscriptionStripeProduct(state, getters, rootState) {
    if (!state.userPlan) return null
    if (!state.userPlan.stripeSubscription) return null
    if (state.userPlan.stripeSubscription.status === 'past_due') return rootState.app.stripePlansSlugMap.free
    if (state.userPlan.stripeSubscription.status === 'active' && state.userPlan.stripeSubscription.cancelAtPeriodEnd) return rootState.app.stripePlansSlugMap.free
    return null
  },
  getAccountIntegrationById(state) {
    return (accountIntegrationId) => {
      const matchedAccountIntegration = state.accountIntegrations.find((accountIntegration) => {
        return accountIntegration.id === accountIntegrationId
      })
      return matchedAccountIntegration || null
    }
  },
  getUserContractOptionByContractId(state) {
    return (contractId) => {
      return state.userContractOptionsContractIdMap[contractId] || null
    }
  },
  telegramGroupChats(state) {
    return state.accountIntegrations.filter((accountIntegration) => {
      return (
        accountIntegration.provider === 'telegram'
        && accountIntegration.defaultOutput.startsWith('-')
      )
    })
  },
  telegramPrivateChats(state) {
    return state.accountIntegrations.filter((accountIntegration) => {
      return (
        accountIntegration.provider === 'telegram'
        && !accountIntegration.defaultOutput.startsWith('-')
      )
    })
  },
  discordDMs(state) {
    return state.accountIntegrations.filter((accountIntegration) => {
      return accountIntegration.provider === 'discord' && accountIntegration.serverId === null
    })
  },
  discordServers(state) {
    return state.accountIntegrations.filter((accountIntegration) => {
      return accountIntegration.provider === 'discord' && accountIntegration.serverId !== null
    })
  },
  accountWebhooks(state) {
    return state.accountIntegrations.filter((accountIntegration) => {
      return accountIntegration.provider === 'webhook'
    })
  },
}
