const createPaginationList = (overrides) => {
  return Object.assign({}, {
    query: '',
    filters: {},
    pageSize: 16,
    totalItems: 0,
    totalPages: 0,
    isLoading: true,
    currentPage: [],
    apiEndpoint: null,
    apiType: 'dispatch',
    filterFormName: null,
    currentPageNumber: 1,
    sortBy: '-inserted_at',
    persistantFilters: {}, // @NOTE: filters specified here are merged with the (user-definied) filters specified in the "filters" object above
    hasFiltersOrQueryApplied: false,
    itemMapper(item, context) {
      return item
    },
  }, overrides)
}

const getDefaultState = () => {
  return {
    userPatches: createPaginationList({
      apiEndpoint: '/patches',
      filterFormName: 'patchOverviewFiltersForm',
      itemMapper(userPatch, context) {
        return Object.assign({}, userPatch, {
          iconUrl: context.rootGetters['app/getPatchEventRowIconUrl'](userPatch),
        })
      },
    }),
    userPatchEvents: createPaginationList({
      apiEndpoint: '/patch-events',
      filterFormName: 'patchHistoryFiltersForm',
      itemMapper(patchEvent, context) {
        return Object.assign({}, patchEvent, {
          iconUrl: context.rootGetters['app/getPatchEventRowIconUrl'](patchEvent.patch),
        })
      },
    }),
    dispatchMonitorEvents: createPaginationList({
      apiEndpoint: '/patch-events',
      filterFormName: 'dispatchMonitorFiltersForm',
      persistantFilters: {
        actionId: [], // @NOTE: this is updated in app/GET_BOOTSTRAP_DATA once we know the value of the dispatch monitor action ID
      },
      itemMapper(dispatchMonitorEvent, context) {
        return Object.assign({}, dispatchMonitorEvent, {
          iconUrl: context.rootGetters['app/getPatchEventRowIconUrl'](dispatchMonitorEvent.patch),
        })
      },
    }),
    userContracts: createPaginationList({
      apiEndpoint: '/account/contracts',
      filterFormName: 'userContractOverviewFiltersForm',
    }),
    userContractEvents: createPaginationList({
      apiEndpoint: '/account/contracts/patch-events',
      filterFormName: 'userContractHistoryFiltersForm',
      itemMapper(patchEvent, context) {
        return Object.assign({}, patchEvent, {
          iconUrl: context.rootGetters['app/getPatchEventRowIconUrl'](patchEvent.patch),
        })
      },
    }),

    // admin stuff below
    accounts: createPaginationList({
      apiType: 'admin',
      apiEndpoint: '/accounts',
    }),
    accountRequests: createPaginationList({
      apiType: 'admin',
      apiEndpoint: '/invitations',
    }),
  }
}

export { getDefaultState }

export default getDefaultState()
