import router from '@/router'
import { sortByName } from '@/utils'
import Mixpanel from '@/plugins/mixpanel'
import Helpscout from '@/plugins/helpscout'

export default {

  INIT({ state, dispatch, rootGetters }) {

    // add auth check when loading new routes
    router.beforeEach((to, from, next) => {

      const isAdmin = rootGetters['user/isAdmin']
      const isAuthenticated = rootGetters['user/isAuthenticated']

      const routeRequiresAuth = to.matched.some((route) => { return route.meta.auth })
      const routeRequiresAdmin = to.matched.some((route) => { return route.meta.admin })
      const routeRequiresGuest = to.matched.some((route) => { return route.meta.guest })

      if (routeRequiresAuth && !isAuthenticated) {
        return next({ name: 'Login', params: { destination: to.path } })
      }

      if (routeRequiresAdmin && (!isAuthenticated || !isAdmin)) {
        return next({ name: 'Login', params: { destination: to.path } })
      }

      if (routeRequiresGuest && isAuthenticated) {

        // normally we want to redirect to the dashboard if they're trying to
        //  view a "guest" page while logged in, but in the special case where
        //  someone lands on the signup page with the intent of creating a new
        //  account using a query-specified email, we'll instead log them out so
        //  the signup form will always be shown with that email
        if (to.name === 'Signup' && to.query.email) {
          return dispatch('LOGOUT', { redirectRouteName: null })
            .then(() => {
              return next()
            })
        }

        return next({ path: '/dashboard' })

      }

      return next()

    })

    // add a check for when a user tries to navigate to the patch create form
    //  but their patches are currently paused due to being over their action
    //  limit (in which case we want to show a confirmation modal before
    //  proceeding)
    router.beforeResolve((to, from) => {

      if (to.name !== 'PatchCreate' || !rootGetters['user/arePatchesPseudoPaused']) return true

      let isResolved = false

      return new Promise((resolve) => {
        dispatch('modals/OPEN_MODAL', {
          name: 'PatchesPseudoPausedReminderModal',
          onClose: () => {
            if (!isResolved && !from.name) {
              router.push({ name: 'Dashboard' })
              resolve()
            }
          },
          props: {
            action: () => {
              isResolved = true
              resolve()
            },
          },
        }, { root: true })
      })

    })

    return dispatch('api/ADD_DISPATCH_INTERCEPTORS', null, { root: true })
      .then(() => {
        if (state.isInMaintenanceMode) return null
        // @NOTE: we don't need to wait for the bootstrap data, we can just let
        //  it load in the background
        dispatch('GET_BOOTSTRAP_DATA')
        return dispatch('GET_LOCALSTORAGE_DATA')
      })
      .then(() => {
        // if an auth token was found in localStorage, log them in
        if (rootGetters['user/isAuthenticated']) {
          return dispatch('AFTER_LOGIN')
            .catch((error) => {
              // this case is handled by the API interceptor so no need to
              //  bubble this error up anymore
              if (error.response && error.response.status === 403) {
                return
              }
              throw error
            })
        }
        return null
      })
  },

  GET_LOCALSTORAGE_DATA({ state, commit, dispatch }) {
    const newAuthTokenData = {
      newAuthToken: JSON.parse(window.localStorage.getItem('dispatch.authToken')),
      newRefreshToken: JSON.parse(window.localStorage.getItem('dispatch.refreshToken')),
      newAuthTokenExpiry: JSON.parse(window.localStorage.getItem('dispatch.authTokenExpiry')),
      newRefreshTokenExpiry: JSON.parse(window.localStorage.getItem('dispatch.refreshTokenExpiry')),
    }
    return dispatch('user/UPDATE_AUTH_TOKEN', newAuthTokenData, { root: true })
  },

  GET_BOOTSTRAP_DATA({ state, rootState, commit, dispatch }) {

    commit('SET_IS_BOOTSTRAP_DATA_LOADING', true)

    // we always want to load all actions, triggers, etc...
    const params = {
      size: 2 ** 32,
    }

    return Promise
      .all([
        dispatch('REFRESH_PATCH_TEMPLATES'),
        dispatch('REFRESH_NETWORKS_AND_CONTRACTS'),
        rootState.api.dispatch.get('/actions', { params }),
        rootState.api.dispatch.get('/triggers', { params }),
        rootState.api.dispatch.get('/stripe/plans', { params }),
        rootState.api.dispatch.get('/stripe/plans/options', { params }),
      ])
      .then(([_patchTemplates, _networks, actions, triggers, stripePlans, stripeAddons]) => {

        const bootstrapData = {
          actions: actions.data,
          triggers: triggers.data,

          // these rest are populated below
          optionMaps: {
            actionOptionsIdMap: {},
            triggerOptionsIdMap: {},

            stripePlansIdMap: {},
            stripePricesIdMap: {},
            stripeAddonsIdMap: {},

            stripePlansSlugMap: {},
            stripePricesSlugMap: {},
            stripeAddonsSlugMap: {},

            actionOptionsSlugMap: {},
            triggerOptionsSlugMap: {},
            actionSettingsSlugMap: {},
            triggerSettingsSlugMap: {},
            triggerNetworkOptionsSlugMap: {},
          },
        }

        // for the beta, only these triggers are supported
        const filteredTriggers = bootstrapData.triggers.filter((trigger) => {
          return (
            trigger.slug === 'address-balance-change'
            || trigger.slug === 'balance-threshold-reached'
            || trigger.slug === 'new-pangolin-listing'
            || trigger.slug === 'new-quickswap-listing'
            || trigger.slug === 'new-sushiswap-listing'
            || trigger.slug === 'new-trader-joe-listing'
            || trigger.slug === 'new-uniswap-v2-listing'
            || trigger.slug === 'new-uniswap-v3-listing'
            || trigger.slug === 'new-pancakeswap-listing'
            || trigger.slug === 'smart-contract-activity'
            || trigger.slug === 'nft-collection-items-transferred'
          )
        })

        // for the beta, only these actions are supported
        const filteredActions = bootstrapData.actions.filter((action) => {
          return (
            action.slug === 'email'
            || action.slug === 'webhook'
            || action.slug === 'discord'
            || action.slug === 'telegram'
            || action.slug === 'dispatch-monitor'
          )
        })

        filteredActions.forEach((action) => {
          action.settings.forEach((setting) => {
            bootstrapData.optionMaps.actionSettingsSlugMap[setting.slug] = setting
          })
        })

        filteredTriggers.forEach((trigger) => {

          trigger.settings.forEach((setting) => {
            bootstrapData.optionMaps.triggerSettingsSlugMap[setting.slug] = setting
          })

          bootstrapData.optionMaps.triggerNetworkOptionsSlugMap[trigger.slug] = trigger.networks
            .sort(sortByName)
            .map((allowedNetwork) => {
              return state.networkOptionsSlugMap[allowedNetwork.slug]
            })

        })

        commit('SET_ACTION_OPTIONS', filteredActions.sort(sortByName).map((action) => {

          const actionOption = {
            id: action.slug,
            value: action.id,
            apiRecord: action,
            label: action.name,
            iconUrl: action.iconUrl,
            description: action.description,
          }

          // hijack this map to build out the actionOptionsSlugMap as well
          bootstrapData.optionMaps.actionOptionsIdMap[action.id] = actionOption
          bootstrapData.optionMaps.actionOptionsSlugMap[action.slug] = actionOption

          return actionOption

        }))

        // @TODO: remove this filtering when dex and/or nft stuff is ready to be
        //  pushed live
        const filteredTriggersWithoutHiddenTriggers = filteredTriggers.filter((trigger) => {
          return !(
            trigger.type === 'v2-dex'
            || trigger.type === 'v3-dex'
            || trigger.slug === 'nft-collection-items-transferred'
          )
        })

        commit('SET_TRIGGER_OPTIONS', filteredTriggersWithoutHiddenTriggers.sort(sortByName).map((trigger) => {

          const triggerOption = {
            id: trigger.slug,
            value: trigger.id,
            apiRecord: trigger,
            label: trigger.name,
            iconUrl: trigger.iconUrl,
            description: trigger.description,
          }

          // hijack this map to build out the triggerOptionsSlugMap as well
          bootstrapData.optionMaps.triggerOptionsIdMap[trigger.id] = triggerOption
          bootstrapData.optionMaps.triggerOptionsSlugMap[trigger.slug] = triggerOption

          return triggerOption

        }))

        const triggerOptionsWithoutDescriptions = state.triggerOptions.map((triggerOption) => {
          return Object.assign({}, triggerOption, { description: null })
        })

        const actionOptionsWithoutDescriptions = state.actionOptions.map((actionOption) => {
          return Object.assign({}, actionOption, { description: null })
        })

        stripePlans.data.forEach((stripePlan) => {

          if (stripePlan.prices) {
            stripePlan.prices.forEach((stripePrice) => {
              bootstrapData.optionMaps.stripePricesIdMap[stripePrice.id] = stripePrice
            })
          }

          if (!stripePlan.metadata.productSlug) return
          bootstrapData.optionMaps.stripePlansIdMap[stripePlan.id] = stripePlan
          bootstrapData.optionMaps.stripePlansSlugMap[stripePlan.metadata.productSlug] = stripePlan

        })

        stripeAddons.data.forEach((stripeAddon) => {
          if (!stripeAddon.metadata.productSlug) return
          bootstrapData.optionMaps.stripeAddonsIdMap[stripeAddon.id] = stripeAddon
          bootstrapData.optionMaps.stripeAddonsSlugMap[stripeAddon.metadata.productSlug] = stripeAddon
        })

        bootstrapData.optionMaps.stripePricesSlugMap = {
          free: bootstrapData.optionMaps.stripePricesIdMap[process.env.VUE_APP_STRIPE_PLAN_FREE_PRICE_ID],
          pro: {
            yearly: bootstrapData.optionMaps.stripePricesIdMap[process.env.VUE_APP_STRIPE_PLAN_PRO_YEARLY_PRICE_ID],
            monthly: bootstrapData.optionMaps.stripePricesIdMap[process.env.VUE_APP_STRIPE_PLAN_PRO_MONTHLY_PRICE_ID],
          },
          starter: {
            yearly: bootstrapData.optionMaps.stripePricesIdMap[process.env.VUE_APP_STRIPE_PLAN_STARTER_YEARLY_PRICE_ID],
            monthly: bootstrapData.optionMaps.stripePricesIdMap[process.env.VUE_APP_STRIPE_PLAN_STARTER_MONTHLY_PRICE_ID],
          },
        }

        commit('SET_OPTION_MAPS', bootstrapData.optionMaps)

        commit('forms/SET_FIELD_OPTIONS', { formName: 'createPatchForm', fieldName: 'actionId', newOptions: state.actionOptions }, { root: true })
        commit('forms/SET_FIELD_OPTIONS', { formName: 'createPatchForm', fieldName: 'triggerId', newOptions: state.triggerOptions }, { root: true })

        commit('forms/SET_FIELD_OPTIONS', { formName: 'dashboardCreatePatchForm', fieldName: 'actionId', newOptions: state.actionOptions }, { root: true })
        commit('forms/SET_FIELD_OPTIONS', { formName: 'dashboardCreatePatchForm', fieldName: 'triggerId', newOptions: state.triggerOptions }, { root: true })

        commit('forms/SET_FIELD_OPTIONS', { formName: 'patchHistoryFiltersForm', fieldName: 'actionId', newOptions: actionOptionsWithoutDescriptions }, { root: true })
        commit('forms/SET_FIELD_OPTIONS', { formName: 'patchHistoryFiltersForm', fieldName: 'triggerId', newOptions: triggerOptionsWithoutDescriptions }, { root: true })

        commit('forms/SET_FIELD_OPTIONS', { formName: 'patchOverviewFiltersForm', fieldName: 'actionId', newOptions: actionOptionsWithoutDescriptions }, { root: true })
        commit('forms/SET_FIELD_OPTIONS', { formName: 'patchOverviewFiltersForm', fieldName: 'triggerId', newOptions: triggerOptionsWithoutDescriptions }, { root: true })

        commit('forms/SET_FIELD_OPTIONS', { formName: 'dispatchMonitorFiltersForm', fieldName: 'triggerId', newOptions: triggerOptionsWithoutDescriptions }, { root: true })

        if (bootstrapData.optionMaps.actionOptionsSlugMap['dispatch-monitor']) {
          commit('pagination/SET_PERSISTANT_FILTERS', {
            listName: 'dispatchMonitorEvents',
            persistantFilters: {
              actionId: [bootstrapData.optionMaps.actionOptionsSlugMap['dispatch-monitor'].apiRecord.id],
            },
          }, { root: true })
        }

        return bootstrapData

      })
      .then(() => {
        commit('SET_IS_BOOTSTRAP_DATA_LOADING', false)
      })

  },

  REFRESH_NETWORKS_AND_CONTRACTS({ state, rootState, commit }) {

    return rootState.api.dispatch.get('/networks', { params: { limit: 2 ** 32 } })
      .then((response) => {

        const networks = response.data

        const optionMaps = {
          networkOptionsIdMap: {},
          contractOptionsIdMap: {},
          networkOptionsSlugMap: {},
          networkContractOptionsByTypeSlugMap: {},
        }

        commit('SET_NETWORK_OPTIONS', networks.sort(sortByName).map((network) => {

          // hijack this map to also build out the network contract options map
          const networkContractOptionsByType = {}

          network.contracts
            .sort(sortByName)
            .forEach((contract) => {

              const label = contract.symbol && contract.type !== 'erc-721' && contract.type !== 'erc-1155'
                ? `${contract.name} - ${contract.symbol}`
                : contract.name

              const contractType = contract.type === 'other' ? 'Other' : contract.type.toUpperCase()

              const contractOption = {
                label,
                id: contract.id,
                value: contract.id,
                apiRecord: contract,
                iconUrl: contract.iconUrl,
                descriptionIconUrl: network.iconUrl,
                description: contract.type === 'base' ? network.name : `${network.name} • ${contractType}`,
                optionGroupName: contract.type === 'base' ? 'BASE CURRENCY' : `DISPATCH CONTRACTS (${contractType})`,
              }

              optionMaps.contractOptionsIdMap[contract.id] = contractOption

              networkContractOptionsByType[contract.type] ||= []
              networkContractOptionsByType[contract.type].push(contractOption)

            })

          const networkOption = {
            id: network.slug,
            value: network.id,
            apiRecord: network,
            label: network.name,
            iconUrl: network.iconUrl,
          }

          optionMaps.networkOptionsIdMap[network.id] = networkOption
          optionMaps.networkOptionsSlugMap[network.slug] = networkOption
          optionMaps.networkContractOptionsByTypeSlugMap[network.slug] = networkContractOptionsByType

          return networkOption

        }))

        commit('SET_OPTION_MAPS', optionMaps)
        commit('SET_CONTRACT_OPTIONS', Object.values(optionMaps.contractOptionsIdMap))

        commit('forms/SET_FIELD_OPTIONS', { formName: 'patchHistoryFiltersForm', fieldName: 'networkId', newOptions: state.networkOptions }, { root: true })
        commit('forms/SET_FIELD_OPTIONS', { formName: 'patchOverviewFiltersForm', fieldName: 'networkId', newOptions: state.networkOptions }, { root: true })
        commit('forms/SET_FIELD_OPTIONS', { formName: 'dispatchMonitorFiltersForm', fieldName: 'networkId', newOptions: state.networkOptions }, { root: true })
        commit('forms/SET_FIELD_OPTIONS', { formName: 'userContractHistoryFiltersForm', fieldName: 'networkId', newOptions: state.networkOptions }, { root: true })
        commit('forms/SET_FIELD_OPTIONS', { formName: 'userContractOverviewFiltersForm', fieldName: 'networkId', newOptions: state.networkOptions }, { root: true })

        return networks

      })

  },

  REFRESH_PATCH_TEMPLATES({ state, rootState, commit }) {

    return rootState.api.dispatch.get('/patches/templates', { params: { size: 2 ** 32, sortBy: '-inserted_at' } })
      .then((response) => {

        const patchTemplatesSlugMap = {}
        const patchTemplates = response.data

        // @TODO: remove this filtering when dex and/or nft stuff is ready to be
        //  pushed live
        const filteredPatchTemplates = patchTemplates.filter((patchTemplate) => {
          return !(
            patchTemplate.trigger.type === 'v2-dex'
            || patchTemplate.trigger.type === 'v3-dex'
            || patchTemplate.trigger.slug === 'nft-collection-items-transferred'
          )
        })

        commit('SET_PATCH_TEMPLATES', filteredPatchTemplates.map((patchTemplate) => {

          const newPatchTemplate = Object.assign({}, patchTemplate, {
            // @NOTE: extra stuff used to be added here (e.g. actionIconUrl &
            //  triggerIconUrl), but that was moved to the backend as part of
            //  the V2 API rewrite
            //
            // I decided to keep this here just in case we ever need to
            //  "embelish" data coming from the backend again
          })

          // hijack this map to build out the patchTemplatesSlugMap as well
          patchTemplatesSlugMap[patchTemplate.slug] = newPatchTemplate

          return newPatchTemplate

        }))

        commit('SET_OPTION_MAPS', { patchTemplatesSlugMap })

      })

  },

  AFTER_LOGIN({ state, rootState, rootGetters, commit, dispatch }) {

    return dispatch('user/GET_USER_DATA', null, { root: true })
      .then(() => {

        const { user, userPlan } = rootState.user

        Helpscout.onReady((helpscout) => {
          helpscout('identify', {
            email: user.email,
            username: user.username,
            company: user.companyName,
            'plan-slug': userPlan.slug,
            'plan-name': userPlan.name,
            name: user.name || user.username,
            'is-ppa-enabled': rootGetters['user/isPPAEnabled'],
          })
        })

        Mixpanel.onReady((mixpanel) => {

          const verifiedAt = new Date(user.verifiedAt || user.insertedAt)

          // if they were verified less than 24 hours ago, consider them a new
          //  user
          const isFirstTimeUser = verifiedAt.toString() !== 'Invalid Date'
            ? Date.now() - verifiedAt.getTime() < 86400000
            : false

          const registerData = {
            isFirstTimeUser,
            planName: userPlan.name,
            signUpDate: user.insertedAt,
            verifiedAt: verifiedAt.toISOString(),
            isPPAEnabled: rootGetters['user/isPPAEnabled'],
            totalPatches: rootState.pagination.userPatches.totalItems,
          }

          mixpanel.identify(user.email)
          mixpanel['esprezzo.io'].identify(user.email)

          mixpanel.register(registerData)
          mixpanel['esprezzo.io'].register(registerData)

        })

      })
  },

  LOGOUT({ state, commit, dispatch }, { redirectRouteName = 'Login', force = false } = {}) {

    commit('user/RESET_STATE', null, { root: true })
    commit('pagination/RESET_STATE', null, { root: true })

    Helpscout.onReady((helpscout) => {
      helpscout('logout')
    })

    Mixpanel.onReady((mixpanel) => {
      mixpanel.reset()
    })

    if (redirectRouteName === null) return null

    // this "force" param is used by the useBeforeUnload composable to know
    //  whether or not to bypass the shouldPreventRouteChange check in the
    //  onBeforeRouteLeave handler
    //
    // this prevents a bug that could occur if the user's auth token expires
    //  while they are in the middle of filling out the Create Patch form... in
    //  which case the auto-logout functionality clashes with the "are you sure
    //  you want to leave this page" warning
    return router.push({ name: redirectRouteName, params: { force } })

  },

}
