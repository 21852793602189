import Mixpanel from '@/plugins/mixpanel'

import { getDefaultState } from './state'

export default {

  RESET_STATE(currentState) {
    Object.assign(currentState, getDefaultState())
    window.localStorage.removeItem('dispatch.authToken')
    window.localStorage.removeItem('dispatch.refreshToken')
    window.localStorage.removeItem('dispatch.authTokenExpiry')
    window.localStorage.removeItem('dispatch.refreshTokenExpiry')
  },

  SET_AUTH_TOKEN(currentState, newAuthToken) {
    currentState.authToken = newAuthToken
    window.localStorage.setItem('dispatch.authToken', JSON.stringify(newAuthToken))
  },

  SET_REFRESH_TOKEN(currentState, newRefreshToken) {
    currentState.refreshToken = newRefreshToken
    window.localStorage.setItem('dispatch.refreshToken', JSON.stringify(newRefreshToken))
  },

  SET_AUTH_TOKEN_EXPIRY(currentState, newAuthTokenExpiry) {
    currentState.authTokenExpiry = newAuthTokenExpiry
    window.localStorage.setItem('dispatch.authTokenExpiry', JSON.stringify(newAuthTokenExpiry))
  },

  SET_REFRESH_TOKEN_EXPIRY(currentState, newRefreshTokenExpiry) {
    currentState.refreshTokenExpiry = newRefreshTokenExpiry
    window.localStorage.setItem('dispatch.refreshTokenExpiry', JSON.stringify(newRefreshTokenExpiry))
  },

  SET_USER(currentState, newUser) {
    currentState.user = newUser
  },

  SET_USER_FLAGS(currentState, newUserFlags) {
    currentState.userFlags = newUserFlags
  },

  SET_USER_PLAN(currentState, newUserPlan) {
    currentState.userPlan = newUserPlan

    Mixpanel.onReady((mixpanel) => {
      mixpanel.register({
        planSlug: newUserPlan.slug,
        planName: newUserPlan.name,
      })
    })
  },

  SET_USER_PLAN_STATS(currentState, newUserPlanStats) {
    currentState.userPlanStats = newUserPlanStats
  },

  SET_USER_API_KEYS(currentState, newUserAPIKeys) {
    currentState.userAPIKeys = newUserAPIKeys
  },

  SET_IS_USER_DATA_LOADING(currentState, newValue) {
    currentState.isUserDataLoading = newValue
  },

  SET_ACCOUNT_INTEGRATIONS(currentState, accountIntegrations) {
    currentState.accountIntegrations = accountIntegrations
  },

  REMOVE_ACCOUNT_INTEGRATION(currentState, accountIntegrationId) {
    currentState.accountIntegrations = currentState.accountIntegrations.filter((accountIntegration) => {
      return accountIntegration.id !== accountIntegrationId
    })
  },

  SET_USER_CONTRACT_OPTION_MAPS(currentState, newMaps) {
    Object.keys(newMaps).forEach((mapName) => {
      currentState[mapName] = newMaps[mapName]
    })
  },

  SET_USER_CONTRACTS(currentState, userContracts) {
    currentState.userContracts = userContracts
  },

  REMOVE_USER_CONTRACT(currentState, userContractId) {
    currentState.userContracts = currentState.userContracts.filter((userContract) => {
      return userContract.id !== userContractId
    })
  },

}
