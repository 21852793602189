export default {

  name: 'auto-blur',

  directive: {

    beforeUnmount($element) {
      if (typeof $element.autoBlurClickListener !== 'function') return
      $element.removeEventListener('click', $element.autoBlurClickListener)
    },

    beforeMount($element) {

      $element.autoBlurClickListener = ($event) => {
        if ($event.target) $event.target.blur()
      }

      $element.addEventListener('click', $element.autoBlurClickListener)

    },

  },

}
