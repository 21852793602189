export const getIconUrl = (type, id) => {

  switch (type) {

    case 'action':
      return `${process.env.VUE_APP_DISPATCH_ICONS_BASE_URL}/actions/${id}.svg`

    case 'trigger':
      return `${process.env.VUE_APP_DISPATCH_ICONS_BASE_URL}/triggers/${id}.svg`

    case 'network':
      return `${process.env.VUE_APP_DISPATCH_ICONS_BASE_URL}/crypto/${id}/network.svg`

    case 'network-unknown':
      return `${process.env.VUE_APP_DISPATCH_ICONS_BASE_URL}/crypto/${id}/unknown.svg`

    default:
      return `${process.env.VUE_APP_DISPATCH_ICONS_BASE_URL}/unknown.svg`

  }

}

export default () => {

  return {
    getIconUrl,
  }

}
