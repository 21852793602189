let isReady = false
const readyQueue = []
const isEnabled = !!process.env.VUE_APP_HELPSCOUT_BEACON_ID && typeof window.Beacon === 'function'

const onReady = (fn = Function.prototype) => {
  if (!isEnabled) return
  if (isReady) fn(window.Beacon)
  else readyQueue.push(fn)
}

const module = {
  isReady,
  onReady,
  isEnabled,
}

export default Object.assign({
  install(app, { token }) {

    if (!isEnabled) return

    isReady = true
    app.provide('$helpscout', module)
    window.Beacon('init', process.env.VUE_APP_HELPSCOUT_BEACON_ID)
    readyQueue.forEach((fn) => { fn(window.Beacon) })

  },
}, module)
