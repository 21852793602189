export default {

  ADD_TOAST(currentState, newToast) {
    currentState.toasts.push(newToast)
  },

  REMOVE_TOAST(currentState, toast) {
    const index = currentState.toasts.indexOf(toast)
    if (index !== -1) currentState.toasts.splice(index, 1)
  },

}
