import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/Home.vue'

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) return { el: to.hash, behavior: 'smooth' }
    return { top: 0 }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/login',
      name: 'Login',
      component: () => { return import(/* webpackChunkName: "Login" */ '@/views/Login.vue') },
      meta: {
        guest: true,
      },
    },
    {
      path: '/error/:errorCode?',
      name: 'Error',
      component: () => { return import(/* webpackChunkName: "Error" */ '@/views/Error.vue') },
    },
    {
      path: '/auth/:authToken',
      name: 'Auth',
      component: () => { return import(/* webpackChunkName: "Auth" */ '@/views/Auth.vue') },
      meta: {
        doNotTrackPageView: true,
      },
    },
    {
      path: '/stripe-checkout-return/:type(success|cancel)',
      name: 'StripeCheckoutReturn',
      component: () => { return import(/* webpackChunkName: "StripeCheckoutReturn" */ '@/views/StripeCheckoutReturn.vue') },
      meta: {
        auth: true,
        doNotTrackPageView: true,
      },
    },
    {
      // @NOTE: newStripePriceId and oldStripePriceId are passed as query params
      //  to this route
      path: '/stripe-subscription-update-return',
      name: 'StripeSubscriptionUpdateReturn',
      component: () => { return import(/* webpackChunkName: "StripeSubscriptionUpdateReturn" */ '@/views/StripeSubscriptionUpdateReturn.vue') },
      meta: {
        auth: true,
        doNotTrackPageView: true,
      },
    },
    {
      path: '/signup',
      name: 'Signup',
      component: () => { return import(/* webpackChunkName: "Signup" */ '@/views/Signup.vue') },
      meta: {
        guest: true,
      },
    },
    {
      path: '/set-password',
      name: 'SetPassword',
      component: () => { return import(/* webpackChunkName: "SetPassword" */ '@/views/SetPassword.vue') },
      meta: {
        auth: true,
      },
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () => { return import(/* webpackChunkName: "Logout" */ '@/views/Logout.vue') },
      meta: {
        auth: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: () => { return import(/* webpackChunkName: "ForgotPassword" */ '@/views/ForgotPassword.vue') },
      meta: {
        guest: true,
      },
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: () => { return import(/* webpackChunkName: "ResetPassword" */ '@/views/ResetPassword.vue') },
      meta: {
        auth: true,
      },
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => { return import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard.vue') },
      meta: {
        auth: true,
      },
    },
    {
      path: '/monitor',
      name: 'DispatchMonitor',
      component: () => { return import(/* webpackChunkName: "Monitor" */ '@/views/DispatchMonitor.vue') },
      meta: {
        auth: true,
      },
    },
    {
      path: '/patches/create',
      name: 'PatchCreate',
      component: () => { return import(/* webpackChunkName: "PatchCreate" */ '@/views/patches/Create.vue') },
      meta: {
        auth: true,
      },
    },
    {
      path: '/patches',
      name: 'Patches',
      component: () => { return import(/* webpackChunkName: "Patches" */ '@/views/Patches.vue') },
      meta: {
        auth: true,
      },
      children: [
        {
          path: '',
          redirect: { name: 'PatchOverview' },
        },
        {
          path: 'overview',
          name: 'PatchOverview',
          component: () => { return import(/* webpackChunkName: "PatchOverview" */ '@/views/patches/Overview.vue') },
        },
        {
          path: 'history/:patchEventId?',
          name: 'PatchHistory',
          component: () => { return import(/* webpackChunkName: "PatchHistory" */ '@/views/patches/History.vue') },
        },
      ],
    },
    {
      path: '/contracts',
      name: 'Contracts',
      component: () => { return import(/* webpackChunkName: "Contracts" */ '@/views/Contracts.vue') },
      meta: {
        auth: true,
      },
      children: [
        {
          path: '',
          redirect: { name: 'ContractOverview' },
        },
        {
          path: 'overview',
          name: 'ContractOverview',
          component: () => { return import(/* webpackChunkName: "ContractOverview" */ '@/views/contracts/Overview.vue') },
        },
        {
          path: 'history',
          name: 'ContractHistory',
          component: () => { return import(/* webpackChunkName: "ContractHistory" */ '@/views/contracts/History.vue') },
        },
      ],
    },
    {
      path: '/explore/:patchTemplateSlug?',
      name: 'Explore',
      component: () => { return import(/* webpackChunkName: "Explore" */ '@/views/Explore.vue') },
      meta: {
        auth: true,
      },
    },
    {
      path: '/account',
      name: 'Account',
      component: () => { return import(/* webpackChunkName: "Account" */ '@/views/Account.vue') },
      meta: {
        auth: true,
      },
      children: [
        {
          path: '',
          redirect: { name: 'AccountSettings' },
        },
        {
          path: 'settings',
          name: 'AccountSettings',
          component: () => { return import(/* webpackChunkName: "AccountSettings" */ '@/views/account/Settings.vue') },
        },
        {
          path: 'connected-apps',
          name: 'ConnectedApplications',
          component: () => { return import(/* webpackChunkName: "ConnectedApplications" */ '@/views/account/ConnectedApplications.vue') },
        },
        {
          path: 'api-keys',
          name: 'ApiKeys', // @NOTE: this needs to be ApiKeys and not APIKeys so the previousRouteName is calculated correctly on the Create Patch page
          component: () => { return import(/* webpackChunkName: "APIKeys" */ '@/views/account/APIKeys.vue') },
        },
        {
          path: 'plans',
          name: 'Plans',
          component: () => { return import(/* webpackChunkName: "Plans" */ '@/views/account/Plans.vue') },
        },
        {
          path: 'usage-and-billing',
          name: 'UsageAndBilling',
          component: () => { return import(/* webpackChunkName: "UsageAndBilling" */ '@/views/account/UsageAndBilling.vue') },
        },
      ],
    },
    {
      path: '/admin',
      name: 'Admin',
      component: () => { return import(/* webpackChunkName: "Admin" */ '@/views/Admin.vue') },
      meta: {
        admin: true,
      },
      children: [
        {
          path: '',
          redirect: { name: 'AdminAccountRequests' },
        },
        {
          path: 'account-requests',
          name: 'AdminAccountRequests',
          component: () => { return import(/* webpackChunkName: "AdminAccountRequests" */ '@/views/admin/AccountRequests.vue') },
        },
        {
          path: 'accounts',
          name: 'AdminAccounts',
          component: () => { return import(/* webpackChunkName: "AdminAccounts" */ '@/views/admin/Accounts.vue') },
        },
        {
          path: 'contracts',
          name: 'AdminContracts',
          component: () => { return import(/* webpackChunkName: "AdminContracts" */ '@/views/admin/Contracts.vue') },
        },
        {
          path: 'patch-templates',
          name: 'AdminPatchTemplates',
          component: () => { return import(/* webpackChunkName: "AdminPatchTemplates" */ '@/views/admin/PatchTemplates.vue') },
        },
        {
          path: 'mailer',
          name: 'AdminMailer',
          component: () => { return import(/* webpackChunkName: "AdminMailer" */ '@/views/admin/Mailer.vue') },
        },
      ],
    },
    {
      name: 'Style Guide',
      path: '/style-guide',
      component: () => { return import(/* webpackChunkName: "StyleGuide" */ '@/views/StyleGuide.vue') },
    },
    {
      name: 'Maintenance',
      path: '/',
      component: () => { return import(/* webpackChunkName: "Maintenance" */ '@/views/Maintenance.vue') },
    },
    // @NOTE: this is a special route used to reload the patch create route
    //  since there's no easy way to reset a vue component (hey it's better than
    //  reloading the whole page!)
    {
      name: 'Redirect',
      path: '/redirect',
      component: () => { return import(/* webpackChunkName: "Redirect" */ '@/views/Redirect.vue') },
      meta: {
        doNotTrackPageView: true,
      },
    },
    {
      path: '/404',
      name: '404',
      component: () => { return import(/* webpackChunkName: "404" */ '@/views/404.vue') },
    },
    {
      path: '/:catchAll(.*)',
      redirect: { name: '404' },
    },
  ],
})

export default router
