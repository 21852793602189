import router from '@/router'

export default {

  ADD_DISPATCH_INTERCEPTORS({ state, rootState, dispatch, rootGetters }) {

    // log the user out if we get a 403 response
    const logoutWhenUnauthenticated = (error) => {

      const now = Date.now()
      const originalRequest = error.config

      if (
        !error
        || !error.response
        || error.response.status !== 403
        || !rootGetters['user/isAuthenticated']

        // explicitly disable 403 redirects for the (re)set password forms since
        //  it could be confusing to have the user redirect to the login page
        //  when an error occurs while setting their password (could be mistaken
        //  as a success)
        || (!router.currentRoute || router.currentRoute.value.name === 'SetPassword' || router.currentRoute.value.name === 'ResetPassword')
      ) {
        return Promise.reject(error)
      }

      // retry a request with 403 response (once), if a valid refresh token is
      //  available
      if (
        originalRequest.isRetry
        || !rootState.user.refreshToken
        || !rootState.user.refreshTokenExpiry
        || new Date(rootState.user.refreshTokenExpiry).getTime() <= now
      ) {
        dispatch('app/LOGOUT', { force: true }, { root: true })
        return Promise.reject(error)
      }

      originalRequest.isRetry = true

      return state.dispatch.put('/session', { refreshToken: rootState.user.refreshToken })
        .then((response) => {

          const newAuthToken = response.data.accessToken

          const newAuthTokenData = {
            newAuthToken,
            newAuthTokenExpiry: response.data.expiresAt,
            newRefreshToken: response.data.refreshToken,
            newRefreshTokenExpiry: rootState.user.refreshTokenExpiry,
          }

          return dispatch('user/UPDATE_AUTH_TOKEN', newAuthTokenData, { root: true })
            .then(() => {
              // @NOTE: the user/UPDATE_AUTH_TOKEN action only updates auth
              //  tokens for FUTURE requests, so we need to set it manually on
              //  the request we want to retry here
              originalRequest.headers.Authorization = `Bearer ${newAuthToken}`
              return state.dispatch(originalRequest)
            })

        })
        .catch((response) => {
          dispatch('app/LOGOUT', { force: true }, { root: true })
          return Promise.reject(error)
        })

    }

    // unwrap paginated requests that we don't really want to paginate... (i.e.
    //  all axios instances that aren't in the pagination store)
    const unwrapPaginatedResponse = (response) => {
      if (Object.hasOwnProperty.call(response.data, 'results') && Object.hasOwnProperty.call(response.data, 'total')) {
        response.headers['x-total-count'] = response.data.total
        response.data = response.data.results
      }
      return response
    }

    state.admin.interceptors.response.use(unwrapPaginatedResponse, logoutWhenUnauthenticated)
    state.dispatch.interceptors.response.use(unwrapPaginatedResponse, logoutWhenUnauthenticated)

  },

  UPDATE_USER_PATCH({ state, dispatch }, newPatchData) {
    return state.dispatch.put(`/patches/${newPatchData.id}`, newPatchData)
      .then((response) => {
        dispatch('pagination/UPDATE_ITEM_IF_IN_CURRENT_PAGE', { listName: 'userPatches', updatedItem: response.data }, { root: true })
        return response.data
      })
  },

  UPDATE_USER_CONTRACT({ state, dispatch, commit }, newUserContractData) {
    return state.dispatch.put(`/account/contracts/${newUserContractData.id}`, newUserContractData)
      .then((response) => {
        dispatch('user/REFRESH_USER_CONTRACTS', null, { root: true })
        dispatch('pagination/UPDATE_ITEM_IF_IN_CURRENT_PAGE', { listName: 'userContracts', updatedItem: response.data }, { root: true })
        return response.data
      })
  },

  DELETE_USER_PATCH({ state, dispatch, commit }, patchId) {
    return state.dispatch.delete(`/patches/${patchId}`)
      .then(() => {
        return Promise.all([
          dispatch('user/REFRESH_USER_PLAN_STATS', null, { root: true }),
          dispatch('pagination/LOAD_PAGE', { listName: 'userPatches' }, { root: true }),
        ])
      })
  },

  DELETE_ACCOUNT_INTEGRATION({ state, commit }, accountIntegrationId) {
    return state.dispatch.delete(`/integrations/${accountIntegrationId}`)
      .then(() => {
        commit('user/REMOVE_ACCOUNT_INTEGRATION', accountIntegrationId, { root: true })
      })
  },

  DELETE_USER_CONTRACT({ state, commit }, userContractId) {
    return state.dispatch.delete(`/account/contracts/${userContractId}`)
      .then(() => {
        commit('user/REMOVE_USER_CONTRACT', userContractId, { root: true })
      })
  },

  APPROVE_ACCOUNT_REQUEST({ state, dispatch }, accountRequestId) {
    return state.admin.put(`/invitations/${accountRequestId}`, { action: 'approve' })
      .then((response) => {
        return dispatch('pagination/UPDATE_ITEM_IF_IN_CURRENT_PAGE', { listName: 'accountRequests', updatedItem: response.data }, { root: true })
      })
  },

  DENY_ACCOUNT_REQUEST({ state, dispatch }, accountRequestId) {
    return state.admin.put(`/invitations/${accountRequestId}`, { action: 'deny' })
      .then((response) => {
        return dispatch('pagination/LOAD_PAGE', { listName: 'accountRequests' }, { root: true })
      })
  },

}
