import eip55 from 'eip55'

import { parseABI } from '@/composables/useABI'
import { getIconUrl } from '@/composables/useIcons'

// contract types don't actually exist in a database table, it's just an enum on
//  the contract table's "type" column... so we could maybe aggregate them by
//  looping over all the returned contracts... but then what happens if the main
//  contract list doesn't return a contract with type "other" for example...
//  this list then wouldn't include "other" but the user could have a user
//  contract with type "other" and wouldn't be able to filter by that type...
//  so we'll just manually list them here for now
const contractTypes = [
  {
    id: 'erc-20',
    value: 'erc-20',
    label: 'ERC-20',
  },
  {
    id: 'erc-721',
    value: 'erc-721',
    label: 'ERC-721',
  },
  {
    id: 'erc-1155',
    value: 'erc-1155',
    label: 'ERC-1155',
  },
  {
    id: 'other',
    value: 'other',
    label: 'Other',
  },
]

const formatAddress = (address) => {

  if (typeof address !== 'string') return address

  try {
    return eip55.encode(address.toLowerCase())
  } catch (error) {
    return address
  }

}

const validateAddress = (value, allowMultipleAddresses = false) => {

  if (allowMultipleAddresses) {

    const message = value.includes(',')
      ? 'Some of these addresses are invalid'
      : 'Please enter a valid address'

    return !/^(0x[0-9a-f]{40},? *)+$/i.test(value)
      ? message
      : null

  }

  if (!/^0x[0-9a-f]{40}$/i.test(value)) {
    return 'Please enter a valid address'
  }

  return null
}

const validateUrl = (value) => {
  if (!/^https?:\/\//.test(value)) {
    return 'Please enter a URL that starts with http:// or https://'
  }
  // @TODO: should ! be allowed in the last group? usually that's a frontend
  //  thing so I would expect a backend url to have one
  if (!/^https?:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/.test(value)) {
    return 'Please enter a valid URL'
  }
  return null
}

const getFormData = (form, context) => {

  const data = {}

  Object.keys(form.fields).forEach((key) => {

    const field = form.fields[key]

    if (typeof field.getFieldData === 'function') {
      data[key] = field.getFieldData(field, form, context)

    } else if (field.metaType === 'timerange') {
      data[key] = new Date(Date.now() - field.value).toISOString()

    } else if (field.metaType === 'currency' || field.metaType === 'formatted-number') {
      data[key] = context.rootState.app.convertStringToNumber(field.value)

    } else {
      data[key] = field.value
    }

  })

  return data

}

const createForm = (overrides) => {
  return Object.assign({
    getFormData,
    apiType: 'dispatch', // "disptach", "admin" or "hubspot" (this is the value in the api vuex state to use)

    isLoading: false,
    isSubmitted: false,

    submitErrors: [],
    submitMethod: 'post',
    submitEndpoint: null,

    fields: {},

    // "template" is an object that can be used to pre-populate a form, the
    //  entries should be field name / value pairs
    template: null,
  }, overrides)
}

const createField = (overrides) => {

  const newField = Object.assign({
    // this field is passed to the <input> tag's "type" field (or indicates that
    //  the <select> or <textarea> tags should be used)
    type: 'text',

    // these fields are also more or less passed directly to the form element
    value: '',
    label: null,
    placeholder: null,

    // these are also set on the form element, and they also have an effect on
    //  validation
    disabled: false,
    required: false,

    // this is a special field that can be used to add "- optional" after the
    //  input's label (we don't normally want that on optional fields)
    explicitlyOptional: false,

    // metaType is a special field that some form components look for to alter
    //  their normal behavior
    //
    // currently supported types are:
    //  - multi
    //    - for checkboxes, groups together a set of options as checkboxes that
    //      all share a single value (an array of chosen options)
    //    - for text inputs, makes validation read from the displayValue instead
    //      of the internal value, and makes the internal value an array (see
    //      displayValue and invalidMultiValues below)
    //  - currency
    //    - for text inputs only, formats the input as USD (e.g. $1,000.00) as
    //      the user types
    //  - formatted-number
    //    - for text inputs only, formats the input as a number with thousands
    //      separators and no trailing zeros (e.g. 1,000.452) as the user types
    //  - timerange
    //    - typically for non-user-specified inputs such as radio buttons,
    //      formats the chosen option as an ISO-8601 date using the field value
    //      as a millisecond offset into the past (i.e. the value will be
    //      calculated as new Date(Date.now() - field.value).toISOString())
    metaType: null,

    // some fields have separate display and internal values, such as the
    //  multi-address input used in the Create Patch form
    //
    // for these fields we need to validate the display value as the user types
    //  instead of the internal value (validation on the actual value is
    //  performed separately in the form component (e.g. FormInput))
    //
    // this allows us to do things like have a text input where a user can enter
    //  a comma- or newline-separated list of values, and "behind the scenes"
    //  and array of valid values can be kept in the form field's "value"
    displayValue: null,

    // for text inputs with a "multi" metaType, we need a way to keep track of
    //  invalid values entered into the text input so we can show the list to
    //  the user (after which they'd presumably fix the invalid entries)
    //
    // this list is kept separately from the internal value so it does not affect
    //  validation (and is not sent to the API) - it's only here to inform the
    //  user
    invalidMultiValues: [],

    // the "note" is a bit of muted text under the field with helpful info
    note: null,

    // similar to the "note", the "labelDescription" is a bit of muted text
    //  under the label (and above the input) with helpful info
    labelDescription: null,

    // options can be an array, only used for types: select, radio, and checkbox
    //  with metaType "multi"
    //
    // for types radio and checkbox with metaType "multi", a "values" array
    //  should be specified along with options
    //
    // the "value" of the field will be the whatever values listed in this array
    //  correspond with the chosen indexes on the options array; therefore both
    //  arrays should always have the same length
    options: null,
    values: null,

    // this is a method you can use to impose validation on the field - return
    //  null for "no error" or a string to show in red text under the field if
    //  there is an error
    //
    // the method will recieve two arguments (value and state) - state is the
    //  current vuex state for the "form" module, which is necessary to acces
    //  other field's values during validation (e.g. when passwordConfirmation
    //  needs to match password)
    validate: null,

    // this is an additional validation method that, when specified, will be
    //  used by "multi" text inputs to determine if there is at least one valid
    //  value in the displayValue to control the disabled state of the "add"
    //  button
    //
    // note that in contrast to validate() above, this method should just return
    //  true or false instead of a string error
    isAtLeastOneMultiEntryValid: null,

    // for text inputs that are not of metaType "currency" or "formatted-number",
    //  this method will be called on as the user inputs text, blurs the input,
    //  or adds a value to a multi input to retrieve a formatted version of the
    //  input
    //
    // note that inputs with metaType "currency" and "formatted-number" have a
    //  bit more complicated formatting logic (i.e. different logic on keyup vs
    //  blur) logic that is defined in the FormInput component
    format: null,

    // error is the text to display in red under the input, this is typically
    //  set with the VALIDATE_FIELD vuex action
    error: null,

    // this field is set to true after a user has interacted with the field and
    //  is used to hide validation & errors before they are necessary
    isDirty: false,

    // this field is used by the FormSelect component to display a loading
    //  overlay when it's options are dynamically refreshing (i.e. when a
    //  Discord server is chosen and the channel list needs to refresh)
    isLoading: false,

    // if this method is specified, it will be passed the field's value and
    //  whatever is returned will be used in place of the atual field value when
    //  getFormData() is called
    //
    // this can be used to manipulate/transform field data when a form is being
    //  submitted (i.e. converting a string to a number)
    getFieldData: null,

  }, overrides)

  // here we'll initialze displayValue to null unless this is a text input
  //  with a metaType of "multi", in which case we'll make it a string - this
  //  is important because there are a few places that key off of this value
  //  being null or a string to know whether to validate the normal "value" or
  //  this "displayValue" (e.g. useFormField composables)
  if (newField.type === 'text' && newField.metaType === 'multi') {
    newField.displayValue = ''
    newField.value = []
  }

  return newField

}

// these are some fields that are used a lot, so they are broken out here to be
//  reduce duplicated code below
const commonFields = {
  email: (overrides) => {
    return createField(Object.assign({
      type: 'email',
      label: 'Email address',
      placeholder: 'Your email address',
      required: true,
      validate(value, state) {
        // this email regex is fairly barebones, but it seems to match what
        //  Chrome does internally ¯\_(ツ)_/¯
        if (!/^(?=\S)[^,]+@(?=\S)[^,]+\.(?=\S)[^,]+$/.test(value)) {
          return 'Please enter a valid email address'
        }
        return null
      },
    }, overrides))
  },
  password: (overrides) => {
    return createField(Object.assign({
      type: 'password',
      label: 'Password',
      placeholder: 'Choose a password',
      required: true,
      validate(value, state) {
        if (!/[a-z]+/.test(value)) {
          return 'Password must contain at least one lowercase letter'
        }
        if (!/[A-Z]+/.test(value)) {
          return 'Password must contain at least one uppercase letter'
        }
        if (!/[^a-z]+/i.test(value)) {
          return 'Password must contain at least one number or special symbol'
        }
        if (value.length < 6) {
          return 'Password must be at least 6 characters'
        }
        return null
      },
    }, overrides))
  },
  passwordConfirmation: (overrides, formName, fieldName = 'password') => {
    return createField(Object.assign({
      type: 'password',
      label: 'Confirm password',
      placeholder: 'Re-type your password',
      required: true,
      validate(value, state) {
        if (value !== state[formName].fields[fieldName].value) {
          return 'Passwords must match'
        }
        return null
      },
    }, overrides))
  },
  recaptchaToken: (overrides) => {
    return createField(Object.assign({
      type: 'hidden',
    }, overrides))
  },
  paginationTimerange: (overrides) => {
    return createField(Object.assign({
      type: 'radio',
      metaType: 'timerange',
      // @NOTE: the disabled values for each option are set in
      //  user/REFRESH_USER_PLAN after we load the user's plan info
      options: [
        {
          id: '1h',
          value: 3600000,
          label: '1h',
          iconUrl: null,
          disabled: false,
        },
        {
          id: '24h',
          value: 86400000,
          label: '24h',
          iconUrl: null,
          disabled: false,
        },
        {
          id: '7d',
          value: 604800000,
          label: '7d',
          iconUrl: null,
          disabled: false,
        },
        {
          id: '30d',
          value: 2592000000,
          label: '30d',
          iconUrl: null,
          disabled: false,
        },
        {
          id: '1y',
          value: 31536000000,
          label: '1y',
          iconUrl: null,
          disabled: false,
        },
      ],
    }, overrides))
  },
}

const getDefaultState = () => {
  return {
    secretSettingsForm: createForm({
      submitEndpoint: null, // @NOTE: this form isn't actually submitted at the moment
      fields: {
        credits: createField({
          type: 'number',
          label: 'Credits',
          validate(value, state) {
            return Number.parseInt(value, 10) < 0
              ? 'Must not be less than 0'
              : null
          },
        }),
        usage: createField({
          type: 'number',
          label: 'Usage',
          disabled: true,
        }),
        overages: createField({
          type: 'number',
          label: 'Overages',
          validate(value, state) {
            return Number.parseInt(value, 10) < 0
              ? 'Must not be less than 0'
              : null
          },
        }),
      },
    }),
    userPlanForm: createForm({
      submitEndpoint: '/stripe/session/checkout',
      getFormData(form, context) {
        return {
          lineItems: [form.fields.stripePriceId.value],
          // @NOTE: the API will append stripeSessionId as a query param to this
          //  url
          cancelUrl: `${process.env.VUE_APP_DISPATCH_URL}/stripe-checkout-return/cancel`,
          successUrl: `${process.env.VUE_APP_DISPATCH_URL}/stripe-checkout-return/success`,
        }
      },
      fields: {
        payPeriod: createField({
          type: 'radio',
          value: 'monthly',
          options: [
            {
              id: 'monthly',
              value: 'monthly',
              label: 'Monthly',
              iconUrl: null,
              accentText: null,
            },
            {
              id: 'yearly',
              value: 'yearly',
              label: 'Yearly',
              iconUrl: null,
              accentText: '- Save 15%',
            },
          ],
        }),
        stripePriceId: createField({}),
        isPayPerActionEnabled: createField({
          type: 'checkbox',
        }),
      },
    }),
    payPerActionConfirmationForm: createForm({
      submitMethod: 'put',
      submitEndpoint: '/account/plan',
      fields: {
        overagesAllowed: createField({
          type: 'checkbox',
          placeholder: 'I understand that I will be charged $0.02 for each Action that exceeds my monthly usage limit.',
          value: false,
        }),
      },
    }),
    adminContractForm: createForm({
      apiType: 'admin',
      submitMethod: null, // @NOTE: this is set before submitting, since the same form is used for updating and creating
      submitEndpoint: null, // @NOTE: this is set before submitting, since the same form is used for updating and creating
      fields: {
        contractId: createField({
          type: 'select',
          label: 'Contract',
          placeholder: 'Select a contract to edit',
          options: [],
        }),
        networkId: createField({
          type: 'select',
          label: 'Network',
          placeholder: 'Select a network',
          required: true,
          options: [],
        }),
        address: createField({
          label: 'Address',
          placeholder: 'e.g. 0x6c3ea9036406852006290770BEdFcAbA0e23A0e8',
          validate: (value) => { return validateAddress(value) },
          format: formatAddress,
          required: true,
        }),
        name: createField({
          label: 'Name',
          placeholder: 'e.g. Wrapped Bitcoin',
          required: true,
        }),
        symbol: createField({
          label: 'Symbol',
          placeholder: 'e.g. WBTC',
          explicitlyOptional: true,
        }),
        type: createField({
          type: 'select',
          label: 'Type',
          placeholder: 'Select contract type',
          disabled: true,
          options: contractTypes,
        }),
      },
    }),
    adminPatchTemplateForm: createForm({
      apiType: 'admin',
      submitMethod: null, // @NOTE: this is set before submitting, since the same form is used for updating and creating
      submitEndpoint: null, // @NOTE: this is set before submitting, since the same form is used for updating and creating
      fields: {
        patchTemplateId: createField({
          type: 'select',
          label: 'Patch Template',
          placeholder: 'Select a Patch Template to edit',
          options: [],
        }),
        triggerId: createField({
          type: 'select',
          label: 'Trigger',
          placeholder: 'Select a trigger',
          required: true,
          options: [],
        }),
        actionId: createField({
          type: 'select',
          label: 'Action',
          placeholder: 'Select a action',
          required: true,
          options: [],
        }),
        networkId: createField({
          type: 'select',
          label: 'Network',
          placeholder: 'Select a network',
          explicitlyOptional: true,
          disabled: true,
          options: [],
        }),
        contractId: createField({
          type: 'select',
          label: 'Contract',
          placeholder: 'Select a contract',
          explicitlyOptional: true,
          disabled: true,
          options: [],
        }),
        name: createField({
          label: 'Name',
          placeholder: 'e.g. Balance changes to Telegram',
          required: true,
        }),
        slug: createField({
          label: 'Slug',
          placeholder: 'e.g. balance-change-to-telegram',
          required: true,
          disabled: true,
        }),
        deeplinkUrl: createField({
          label: 'Deeplink URL',
          disabled: true,
        }),
        description: createField({
          type: 'textarea',
          label: 'Description',
          placeholder: 'e.g. Post in Telegram when the balance of a specific asset changes in one or more addresses',
          required: true,
        }),
      },
    }),
    nftMediaCachingForm: createForm({
      submitEndpoint: '/support/request-nft-media-caching',
      fields: {
        recaptchaToken: commonFields.recaptchaToken(),
        nftMediaCaching: createField({
          type: 'radio',
          label: 'Notify me when NFT media & metadata caching is available',
          options: [
            {
              id: 'yes',
              value: 'Yes',
              label: 'Yes',
              iconUrl: null,
            },
            {
              id: 'no',
              value: 'No',
              label: 'No',
              iconUrl: null,
            },
          ],
          required: true,
        }),
      },
    }),
    createUserContractForm: createForm({
      // @NOTE: the submit logic for this form is a bit complex due to juggling
      //  verified vs unverified contract flows, so form submission is actually
      //  handled in the CreateUserContractModal component
      submitEndpoint: null,
      fields: {
        networkId: createField({
          type: 'select',
          label: 'Network',
          placeholder: 'Select the network the token is on',
          required: true,
          options: [], // set in create form when modal is launched
        }),
        ambiguousContractAddress: createField({
          label: 'Contract address',
          placeholder: 'Address of smart contract starting with 0x...',
          validate: validateAddress,
          format: formatAddress,
          required: true,
        }),
        contractNickname: createField({
          label: 'Contract nickname',
          placeholder: 'A descriptive name you\'ll recognize later',
          required: true,
        }),
        isProxyContract: createField({
          type: 'radio',
          label: 'Is this a proxy contract?',
          options: [
            {
              id: 'yes',
              value: true,
              label: 'Yes',
              iconUrl: null,
            },
            {
              id: 'no',
              value: false,
              label: 'No / Not sure',
              iconUrl: null,
            },
          ],
        }),
        trackActivityIn: createField({
          type: 'checkbox',
          metaType: 'multi',
          label: 'Track activity in...',
          options: [
            {
              id: 'proxy',
              value: 'proxy',
              label: 'Proxy contract',
              iconUrl: null,
            },
            {
              id: 'implementation',
              value: 'implementation',
              label: 'Implementation contract',
              iconUrl: null,
            },
          ],
          value: [],
        }),
        proxyContractAddress: createField({
          label: 'Contract address',
          placeholder: 'Address of smart contract starting with 0x...',
          format: formatAddress,
          validate: validateAddress,
          required: false, // @NOTE: this is controlled by the value of isProxyContract and trackActivityIn
        }),
        proxyContractABIJSON: createField({
          type: 'textarea',
          label: 'Contract ABI JSON',
          placeholder: 'Paste JSON ABI code here',
          required: false, // @NOTE: this is controlled by the value of isProxyContract and trackActivityIn
          validate(value, state) {

            try {

              const { events, functions } = parseABI(value)

              return events.length === 0 && functions === 0
                ? 'This contract ABI doesn\'t appear to contain any valid events or non-view/non-pure functions.'
                : null

            } catch (error) {
              return error.message
            }

          },
        }),
        implementationContractAddress: createField({
          label: 'Contract address',
          placeholder: 'Address of smart contract starting with 0x...',
          format: formatAddress,
          note: 'Please note: Dispatch doesn\'t currently auto-update monitoring when proxy contracts are updated with new implementation contract addresses.',
          required: false, // @NOTE: this is controlled by the value of isProxyContract and trackActivityIn
          validate: (value, state) => {
            if (value === state.createUserContractForm.fields.proxyContractAddress.value) {
              return 'Implementation contract address must not match the proxy contract address'
            }
            return validateAddress(value)
          },
        }),
        implementationContractABIJSON: createField({
          type: 'textarea',
          label: 'Contract ABI JSON',
          placeholder: 'Paste JSON ABI code here',
          required: false, // @NOTE: this is controlled by the value of isProxyContract and trackActivityIn
          validate(value, state) {

            try {

              const { events, functions } = parseABI(value)

              return events.length === 0 && functions === 0
                ? 'This contract ABI doesn\'t appear to contain any valid events or non-view/non-pure functions.'
                : null

            } catch (error) {
              return error.message
            }

          },
        }),
      },
    }),
    adminMailerForm: createForm({
      apiType: 'admin',
      submitMethod: 'post',
      submitEndpoint: '/mailer',
      getFormData(form, context) {
        const formData = getFormData(form, context)
        const templateParams = JSON.parse(formData.templateParams || '{}')
        delete formData.templateParams
        return { ...formData, templateParams }
      },
      fields: {
        recipients: createField({
          label: 'Recipients',
          placeholder: 'email-1@example.com, email-2@example.com, ...',
          note: 'A comma separated list of emails',
          required: true,
          validate(value, state) {
            // this email regex is fairly barebones, but it seems to match what
            //  Chrome does internally ¯\_(ツ)_/¯
            if (!/^((?=\S)[^,]+@(?=\S)[^,]+\.(?=\S)[^,]+,?)+$/.test(value)) {
              return 'Please enter a valid email address'
            }
            return null
          },
        }),
        templateId: createField({
          label: 'Template ID',
          placeholder: 'd-96025b7daf1a4366ad629ac0b88dc7cd',
          note: 'Found in SendGrid under Email API > Dynamic Templates',
          required: true,
        }),
        templateParams: createField({
          type: 'textarea',
          label: 'Template data',
          explicitlyOptional: true,
          note: 'Leave empty if template has no variables',
          placeholder: JSON.stringify({ variable_one: 123, variable_two: 'abc' }, null, '  '),
          validate(value, state) {
            if (!value) return null
            try {
              JSON.parse(value)
              return null
            } catch (error) {
              return 'JSON syntax error. Please make sure your JSON is properly formatted and try again.'
            }
          },
        }),
      },
    }),
    renamePatchForm: createForm({
      submitMethod: 'put',
      submitEndpoint: null, // @NOTE: set just before submitting the form, since the endpoint is dependent on the patch id
      fields: {
        name: createField({
          label: 'New Patch name',
          note: 'This is how the Patch will appear in Patch Overview and History; choose a descriptive name.',
          required: true,
        }),
      },
    }),
    renameUserContractForm: createForm({
      submitMethod: 'put',
      submitEndpoint: null, // @NOTE: this is not actually used, data is submitted via the api/UPDATE_USER_CONTRACT action
      fields: {
        name: createField({
          label: 'Contract nickname',
          note: 'A descriptive name you\'ll recognize later',
          required: true,
        }),
      },
    }),
    createAccountWebhookForm: createForm({
      submitEndpoint: '/integrations',
      fields: {
        provider: createField({
          type: 'hidden',
          value: 'webhook',
        }),
        name: createField({
          label: 'Webhook name',
          placeholder: 'Name of the app or service Dispatch will send data to',
          required: true,
        }),
        defaultOutput: createField({
          label: 'Webhook URL',
          placeholder: 'URL for the webhook endpoint where Dispatch will send data',
          note: 'This endpoint should be able to accept a JSON payload via an HTTP POST request',
          validate: validateUrl,
          required: true,
        }),
      },
    }),
    patchHistoryFiltersForm: createForm({
      submitEndpoint: null,
      fields: {
        // @NOTE: no label is shown for this field
        query: createField({
          type: 'search',
          required: false,
          placeholder: 'Filter by Patch name...',
        }),
        // @NOTE: these fields are set in app/GET_BOOTSTRAP_DATA and/or
        //  user/GET_USER_DATA
        //
        // @NOTE: also, the order listed here is the order they are laid out on
        //  the page
        networkId: createField({
          type: 'checkbox',
          metaType: 'multi',
          placeholder: 'Network',
          options: [],
          value: [],
        }),
        triggerId: createField({
          type: 'checkbox',
          metaType: 'multi',
          placeholder: 'Trigger',
          options: [],
          value: [],
        }),
        actionId: createField({
          type: 'checkbox',
          metaType: 'multi',
          placeholder: 'Action',
          options: [],
          value: [],
        }),
        'insertedAt[after]': commonFields.paginationTimerange(),
      },
    }),
    patchOverviewFiltersForm: createForm({
      submitEndpoint: null,
      fields: {
        // @NOTE: no label is shown for this field
        query: createField({
          type: 'search',
          required: false,
          placeholder: 'Filter by Patch name...',
        }),
        // @NOTE: these fields are set in app/GET_BOOTSTRAP_DATA and/or
        //  user/GET_USER_DATA
        //
        // @NOTE: also, the order listed here is the order they are laid out on
        //  the page
        networkId: createField({
          type: 'checkbox',
          metaType: 'multi',
          placeholder: 'Network',
          options: [],
          value: [],
        }),
        triggerId: createField({
          type: 'checkbox',
          metaType: 'multi',
          placeholder: 'Trigger',
          options: [],
          value: [],
        }),
        actionId: createField({
          type: 'checkbox',
          metaType: 'multi',
          placeholder: 'Action',
          options: [],
          value: [],
        }),
      },
    }),
    dispatchMonitorFiltersForm: createForm({
      submitEndpoint: null,
      fields: {
        // @NOTE: no label is shown for this field
        query: createField({
          type: 'search',
          required: false,
          placeholder: 'Filter by Patch name...',
        }),
        // @NOTE: these fields are set in app/GET_BOOTSTRAP_DATA and/or
        //  user/GET_USER_DATA
        //
        // @NOTE: also, the order listed here is the order they are laid out on
        //  the page
        networkId: createField({
          type: 'checkbox',
          metaType: 'multi',
          placeholder: 'Network',
          options: [],
          value: [],
        }),
        triggerId: createField({
          type: 'checkbox',
          metaType: 'multi',
          placeholder: 'Trigger',
          options: [],
          value: [],
        }),
        'insertedAt[after]': commonFields.paginationTimerange(),
      },
    }),
    userContractHistoryFiltersForm: createForm({
      submitEndpoint: null,
      fields: {
        // @NOTE: no label is shown for this field
        query: createField({
          type: 'search',
          required: false,
          placeholder: 'Filter by contract name...',
        }),
        // @NOTE: these fields are set in app/GET_BOOTSTRAP_DATA and/or
        //  user/GET_USER_DATA
        //
        // @NOTE: also, the order listed here is the order they are laid out on
        //  the page
        networkId: createField({
          type: 'checkbox',
          metaType: 'multi',
          placeholder: 'Network',
          options: [],
          value: [],
        }),
        // this was previously "contractType" but the API expects "type" now
        type: createField({
          type: 'checkbox',
          metaType: 'multi',
          placeholder: 'Contract Type',
          options: contractTypes,
          value: [],
        }),
        'insertedAt[after]': commonFields.paginationTimerange(),
      },
    }),
    userContractOverviewFiltersForm: createForm({
      submitEndpoint: null,
      fields: {
        // @NOTE: no label is shown for this field
        query: createField({
          type: 'search',
          required: false,
          placeholder: 'Filter by contract name...',
        }),
        // @NOTE: these fields are set in app/GET_BOOTSTRAP_DATA and/or
        //  user/GET_USER_DATA
        //
        // @NOTE: also, the order listed here is the order they are laid out on
        //  the page
        networkId: createField({
          type: 'checkbox',
          metaType: 'multi',
          placeholder: 'Network',
          options: [],
          value: [],
        }),
        // this was previously "contractType" but the API expects "type" now
        type: createField({
          type: 'checkbox',
          metaType: 'multi',
          placeholder: 'Contract Type',
          options: contractTypes,
          value: [],
        }),
      },
    }),
    apiKeyRequestForm: createForm({
      submitEndpoint: '/support/request-api-key',
      fields: {
        recaptchaToken: commonFields.recaptchaToken(),
        // @NOTE: this needs to be dispatchApiUseCase (and not dispatchAPIUseCase) for the snake_case conversion that happens on the API side to translate properly
        dispatchApiUseCase: createField({
          type: 'textarea',
          label: 'Tell us a little about how you plan to use the API',
          placeholder: 'I\'m building an app that...',
          required: true,
        }),
      },
    }),
    changePasswordForm: createForm({
      submitMethod: 'put',
      submitEndpoint: '/account/password',
      fields: {
        currentPassword: createField({
          type: 'password',
          label: 'Verify current password',
          placeholder: 'Enter your current password',
          required: true,
        }),
        password: commonFields.password({
          label: 'New password',
        }),
        passwordConfirmation: commonFields.passwordConfirmation({
          label: 'Confirm new password',
        }, 'changePasswordForm'),
      },
    }),
    accountSettingsForm: createForm({
      fields: {
        email: createField({
          type: 'email',
          label: 'Email',
          disabled: true,
        }),
        username: createField({
          label: 'Username',
          disabled: true,
        }),
      },
    }),
    suggestAPatchForm: createForm({
      submitEndpoint: '/support/suggest-a-patch',
      fields: {
        recaptchaToken: commonFields.recaptchaToken(),
        suggestion: createField({
          type: 'textarea',
          label: 'Tell us about the Trigger, Action or Template',
          placeholder: 'I\'d like to receive a Discord message when...',
          required: true,
        }),
      },
    }),
    nftRequestForm: createForm({
      submitEndpoint: '/support/suggest-an-nft',
      fields: {
        recaptchaToken: commonFields.recaptchaToken(),
        nftCollectionName: createField({
          label: 'Collection name',
          placeholder: 'Name of the NFT collection',
          required: true,
        }),
        network: createField({
          label: 'Network',
          placeholder: 'Which blockchain is the collection on?',
          required: true,
        }),
        contractAddress: createField({
          label: 'Contract address',
          placeholder: 'Address of the NFT smart contract',
          required: true,
          validate: validateAddress,
          format: formatAddress,
        }),
      },
    }),
    tokenRequestForm: createForm({
      submitEndpoint: '/support/suggest-a-token',
      fields: {
        recaptchaToken: commonFields.recaptchaToken(),
        tokenName: createField({
          label: 'Token name',
          placeholder: 'Name of the token you wish to monitor',
          required: true,
        }),
        tokenSymbol: createField({
          label: 'Token symbol',
          placeholder: 'What is the token symbol? (Examples: ETH, BTC)',
          required: true,
        }),
        network: createField({
          label: 'Network',
          placeholder: 'Which blockchain is the token on?',
          required: true,
        }),
        contractAddress: createField({
          label: 'Contract address',
          placeholder: 'Address of the token\'s smart contract',
          required: true,
          validate: validateAddress,
          format: formatAddress,
        }),
      },
    }),
    createPatchForm: createForm({
      submitEndpoint: '/patches',
      template: {
        triggerId: '',
        actionId: '',
        networkId: '',
        contractId: '',
        userContractId: '',
        userContractType: '',
        userContractAddress: '',
        userContractProxyEvents: [],
        userContractProxyFunctions: [],
        userContractImplementationEvents: [],
        userContractImplementationFunctions: [],
        addressBalanceChangeThreshold: '',
        addressBalanceChangeAddressList: [],
        addressBalanceChangeThresholdDirection: [],
        email: '',
        telegramActionEvent: '',
        telegramAccountIntegrationId: '',
        discordActionEvent: '',
        discordAccountIntegrationId: '',
        discordChannelId: '',
        webhookAccountIntegrationId: '',
        name: '',
      },
      getFormData(form, context) {

        const { rootState, rootGetters } = context

        const actionId = form.fields.actionId.value || null
        const triggerId = form.fields.triggerId.value || null

        const action = actionId ? rootState.app.actionOptionsIdMap[actionId].apiRecord : {}
        const trigger = triggerId ? rootState.app.triggerOptionsIdMap[triggerId].apiRecord : {}

        const networkId = form.fields.networkId.value || null
        const contractId = (trigger.type === 'user-contract' ? form.fields.userContractId.value : form.fields.contractId.value) || null

        const patchSettings = []

        switch (trigger.slug) {

          case 'address-balance-change': {

            const setting = rootState.app.triggerSettingsSlugMap['address-list']

            patchSettings.push({
              settingId: setting.id,
              settingSlug: setting.slug,
              value: form.fields.addressBalanceChangeAddressList.value.join(','),
            })

            break

          }

          case 'balance-threshold-reached': {

            const aboveSetting = rootState.app.triggerSettingsSlugMap['above-threshold']
            const belowSetting = rootState.app.triggerSettingsSlugMap['below-threshold']
            const addressSetting = rootState.app.triggerSettingsSlugMap['address-list']

            const threshold = rootState.app.convertStringToNumber(form.fields.addressBalanceChangeThreshold.value)

            if (form.fields.addressBalanceChangeThresholdDirection.value.includes('above')) {
              patchSettings.push({
                value: threshold,
                settingId: aboveSetting.id,
                settingSlug: aboveSetting.slug,
              })
            }

            if (form.fields.addressBalanceChangeThresholdDirection.value.includes('below')) {
              patchSettings.push({
                value: threshold,
                settingId: belowSetting.id,
                settingSlug: belowSetting.slug,
              })
            }

            patchSettings.push({
              settingId: addressSetting.id,
              settingSlug: addressSetting.slug,
              value: form.fields.addressBalanceChangeAddressList.value.join(','),
            })

            break
          }

          case 'smart-contract-activity': {

            const proxyEventListSetting = rootState.app.triggerSettingsSlugMap['proxy-event-list']
            const implementationEventListSetting = rootState.app.triggerSettingsSlugMap['implementation-event-list']

            const proxyFunctionListSetting = rootState.app.triggerSettingsSlugMap['proxy-function-list']
            const implementationFunctionListSetting = rootState.app.triggerSettingsSlugMap['implementation-function-list']

            if (form.fields.userContractProxyEvents.value.length !== 0) {
              patchSettings.push({
                settingId: proxyEventListSetting.id,
                settingSlug: proxyEventListSetting.slug,
                value: form.fields.userContractProxyEvents.value.join(','),
              })
            }

            if (form.fields.userContractImplementationEvents.value.length !== 0) {
              patchSettings.push({
                settingId: implementationEventListSetting.id,
                settingSlug: implementationEventListSetting.slug,
                value: form.fields.userContractImplementationEvents.value.join(','),
              })
            }

            if (form.fields.userContractProxyFunctions.value.length !== 0) {
              patchSettings.push({
                settingId: proxyFunctionListSetting.id,
                settingSlug: proxyFunctionListSetting.slug,
                value: form.fields.userContractProxyFunctions.value.join(','),
              })
            }

            if (form.fields.userContractImplementationFunctions.value.length !== 0) {
              patchSettings.push({
                settingId: implementationFunctionListSetting.id,
                settingSlug: implementationFunctionListSetting.slug,
                value: form.fields.userContractImplementationFunctions.value.join(','),
              })
            }

            break

          }

          // the following triggers have no settings
          case 'new-elk-listing':
          case 'new-pangolin-listing':
          case 'new-quickswap-listing':
          case 'new-sushiswap-listing':
          case 'new-trader-joe-listing':
          case 'new-uniswap-v2-listing':
          case 'new-uniswap-v3-listing':
          case 'new-pancakeswap-listing':
          case 'nft-collection-items-transferred':
            break

          default:
            // do nothing
        }

        switch (action.slug) {
          case 'email': {

            const setting = rootState.app.actionSettingsSlugMap['email-address']

            patchSettings.push({
              settingId: setting.id,
              settingSlug: setting.slug,
              value: form.fields.email.value,
            })

            break

          }

          case 'webhook':
          case 'telegram': {

            const setting = rootState.app.actionSettingsSlugMap.integration

            // get either webhookAccountIntegrationId or
            //  telegramAccountIntegrationId based on the action.slug
            const accountIntegrationFieldName = `${action.slug}AccountIntegrationId`
            const accountIntegration = rootGetters['user/getAccountIntegrationById'](form.fields[accountIntegrationFieldName].value) || {}

            patchSettings.push({
              settingId: setting.id,
              settingSlug: setting.slug,
              value: accountIntegration.name,
              integrationId: accountIntegration.id,
              integrationChannelId: accountIntegration.defaultOutput,
            })

            break

          }

          case 'discord': {

            const setting = rootState.app.actionSettingsSlugMap.integration

            const accountIntegration = rootGetters['user/getAccountIntegrationById'](form.fields.discordAccountIntegrationId.value) || {}

            const selectedDiscordChannelIdOption = form.fields.discordChannelId.options.find((option) => {
              return option.value === form.fields.discordChannelId.value
            }) || form.fields.discordChannelId.options[0] || { apiRecord: {} }

            // @NOTE: we use selectedDiscordChannelIdOption.label instead of
            //  selectedDiscordChannelIdOption.apiRecord.name below since that
            //  already includes the "styling" for discord channel names (i.e.
            //  "#channel-name" instead of simply "channel-name")
            const value = form.fields.discordActionEvent.value === 'server-channel'
              ? selectedDiscordChannelIdOption.label
              : accountIntegration.name

            patchSettings.push({
              value,
              settingId: setting.id,
              settingSlug: setting.slug,
              integrationChannelId: form.fields.discordChannelId.value,
              integrationId: form.fields.discordAccountIntegrationId.value,
            })

            break

          }

          case 'dispatch-monitor':
            // no settings
            break

          default:
            // do nothing

        }

        return {
          actionId,
          triggerId,
          networkId,
          contractId,
          settings: patchSettings,
          name: form.fields.name.value,
        }

      },
      fields: {
        triggerId: createField({
          type: 'select',
          label: 'When this happens...',
          placeholder: 'Choose a Trigger event',
          options: [], // set in app/GET_BOOTSTRAP_DATA
        }),
        actionId: createField({
          type: 'select',
          label: 'Application',
          placeholder: 'Choose an app',
          options: [], // set in app/GET_BOOTSTRAP_DATA
        }),
        networkId: createField({
          type: 'select',
          label: 'Network',
          placeholder: 'Select the network the token is on',
          options: [], // set in create form after a trigger is chosen
        }),
        contractId: createField({
          type: 'select',
          label: 'Token',
          placeholder: 'Select a token to monitor',
          disabled: true,
          options: [], // set in create form after a network is chosen
        }),

        // for all user contract trigger types

        // @NOTE: this is a special version of the contractId field, since it's
        //  so different than the normal one it makes more sense to keep this
        //  separate instead of trying to cram all the special logic into the
        //  existing field
        userContractId: createField({
          type: 'select',
          label: 'Smart contract',
          placeholder: 'Select a contract to monitor',
          disabled: true,
          options: [], // set in create form after a network is chosen
        }),
        // @NOTE: the options for this field aren't really ever set, since the
        //  user doesn't actually choose a value from this list
        //
        // the type is auto-detected on the backend and just displayed in this
        //  field... but since it needs to "appear" as a disabled select box,
        //  the options array is just set to a single item that matches the
        //  auto-detected type that comes form the backend... see the
        //  selectedUserContractOption watcher on the patch create form
        userContractType: createField({
          type: 'select',
          label: 'Contract type',
          placeholder: 'Select the smart contract type',
          disabled: true, // this field is always disabled, it's really just there for display
          options: [],
        }),
        userContractAddress: createField({
          label: 'Contract address',
          placeholder: 'Select a contract to monitor',
          disabled: true, // this field is always disabled, it's really just there for display
        }),
        userContractProxyEvents: createField({
          type: 'checkbox',
          metaType: 'multi',
          label: 'Events',
          labelDescription: 'Select all the events you want to use as Triggers',
          options: [], // set in create form after a contract is chosen or created
          // while this is technically required, the create patch form has logic
          //  that prevents the step from being submitted if an option here or
          //  in the other event/function lists is not chosen
          required: false,
          value: [],
        }),
        userContractImplementationEvents: createField({
          type: 'checkbox',
          metaType: 'multi',
          label: 'Events',
          labelDescription: 'Select all the events you want to use as Triggers',
          options: [], // set in create form after a contract is chosen or created
          // while this is technically required, the create patch form has logic
          //  that prevents the step from being submitted if an option here or
          //  in the other event/function lists is not chosen
          required: false,
          value: [],
        }),
        userContractProxyFunctions: createField({
          type: 'checkbox',
          metaType: 'multi',
          label: 'Functions',
          labelDescription: 'Select all the functions you want to use as Triggers',
          options: [], // set in create form after a contract is chosen or created
          // while this is technically required, the create patch form has logic
          //  that prevents the step from being submitted if an option here or
          //  in the other event/function lists is not chosen
          required: false,
          value: [],
        }),
        userContractImplementationFunctions: createField({
          type: 'checkbox',
          metaType: 'multi',
          label: 'Functions',
          labelDescription: 'Select all the functions you want to use as Triggers',
          options: [], // set in create form after a contract is chosen or created
          // while this is technically required, the create patch form has logic
          //  that prevents the step from being submitted if an option here or
          //  in the other event/function lists is not chosen
          required: false,
          value: [],
        }),

        // for all balance change trigger types
        addressBalanceChangeAddressList: createField({
          metaType: 'multi',
          label: 'Address(es)',
          placeholder: 'Enter address(es) you wish to monitor',
          note: 'Use commas or line breaks when entering multiple addresses',
          validate: (value) => { return validateAddress(value, true) },
          isAtLeastOneMultiEntryValid: (value) => {
            return (value || '').split(',').some((address) => {
              return validateAddress(address.trim()) === null
            })
          },
          format: formatAddress,
          disabled: true,
        }),

        // for balance threshold trigger type
        addressBalanceChangeThreshold: createField({
          metaType: 'formatted-number',
          label: 'Balance threshold',
          placeholder: 'Enter a number',
          disabled: true,
        }),
        addressBalanceChangeThresholdDirection: createField({
          type: 'checkbox',
          metaType: 'multi',
          label: 'When balance goes...',
          options: [
            {
              id: 'above',
              value: 'above',
              label: 'Above threshold',
              iconUrl: null,
            },
            {
              id: 'below',
              value: 'below',
              label: 'Below threshold',
              iconUrl: null,
            },
          ],
          value: [],
          disabled: true,
        }),

        email: commonFields.email({
          placeholder: 'Enter the email address you wish to receive alerts',
          required: false,
        }),
        telegramActionEvent: createField({
          type: 'select',
          label: 'Action event',
          placeholder: 'What would you like to happen in Telegram?',
          options: [
            {
              value: 'private',
              label: 'Send a private Telegram message',
              description: 'Send a direct message to me in Telegram',
            },
            {
              value: 'group',
              label: 'Post in a Telegram group or channel',
              description: 'Post a message in a Telegram group or channel (you must be an Admin of the group or channel)',
            },
          ],
        }),
        telegramAccountIntegrationId: createField({
          type: 'select',
          label: 'Destination',
          placeholder: 'Where should Dispatch send Telegram messages?',
          options: [], // set in forms/REFRESH_TELEGRAM_DESTINATION_OPTIONS action
          disabled: true,
        }),
        discordActionEvent: createField({
          type: 'select',
          label: 'Action event',
          placeholder: 'What would you like to happen in Discord?',
          options: [
            {
              value: 'dm-channel',
              label: 'Send a Discord direct message',
              description: 'Send a direct message to me in Discord',
            },
            {
              value: 'server-channel',
              label: 'Post in a Discord server',
              description: 'Post a message in a Discord server channel',
            },
          ],
        }),
        discordAccountIntegrationId: createField({
          type: 'select',
          label: 'Destination',
          placeholder: 'Where should Dispatch send Discord messages?',
          options: [], // set in forms/REFRESH_DISCORD_DESTINATION_OPTIONS action
          disabled: true,
        }),
        discordChannelId: createField({
          type: 'select',
          label: 'Discord server channel',
          placeholder: 'Which Discord server channel should Dispatch post in?',
          options: [], // set in forms/REFRESH_DISCORD_SERVER_CHANNEL_OPTIONS action
          disabled: true,
        }),
        webhookAccountIntegrationId: createField({
          type: 'select',
          label: 'Webhook',
          placeholder: 'Select an existing webhook or create a new one',
          options: [], // set in forms/REFRESH_ACCOUNT_WEBHOOK_OPTIONS action
        }),
        // @NOTE: no need to validate the url on this field since it's always
        //  disabled (it's really only there to show the URL of the selected
        //  webhook)
        webhookUrl: createField({
          label: 'Webhook URL',
          placeholder: 'URL for the webhook endpoint where Dispatch will send data',
          disabled: true,
        }),
        name: createField({
          label: 'Patch name',
          note: 'This is how the Patch will appear in Patch Overview and History; choose a descriptive name.',
          required: true,
        }),

        // @NOTE: these are a placeholder fields that are always disabled
        webhookActionEvent: createField({
          label: 'Action event',
          value: 'Send a JSON payload to a webhook endpoint',
          disabled: true,
        }),
        emailActionEvent: createField({
          label: 'Action event',
          value: 'Send an email',
          disabled: true,
        }),
        dispatchMonitorActionEvent: createField({
          label: 'Action event',
          value: 'Post in Dispatch Monitor',
          disabled: true,
        }),
      },
    }),
    dashboardCreatePatchForm: createForm({
      fields: {
        triggerId: createField({
          type: 'select',
          label: 'When this happens...',
          placeholder: 'Select a Trigger',
          options: [], // set in app/GET_BOOTSTRAP_DATA
        }),
        actionId: createField({
          type: 'select',
          label: 'do this:',
          placeholder: 'Select an Action',
          options: [], // set in app/GET_BOOTSTRAP_DATA
        }),
      },
    }),
    resetPasswordForm: createForm({
      submitMethod: 'put',
      submitEndpoint: '/account/password',
      fields: {
        password: commonFields.password({ label: 'New password' }),
        passwordConfirmation: commonFields.passwordConfirmation({}, 'resetPasswordForm'),
      },
    }),
    forgotPasswordForm: createForm({
      submitEndpoint: '/account/recover',
      fields: {
        email: commonFields.email(),
        recaptchaToken: commonFields.recaptchaToken(),
      },
    }),
    loginForm: createForm({
      submitEndpoint: '/session',
      fields: {
        email: commonFields.email({ placeholder: '' }),
        // @NOTE: this is explicitly not using commonFields.password() so there
        //  is no validation as the user types in the login form
        password: createField({
          type: 'password',
          label: 'Password',
          required: true,
        }),
        keepMeLoggedIn: createField({
          type: 'checkbox',
          placeholder: 'Keep me logged in for 2 weeks',
          value: false,
        }),
        recaptchaToken: commonFields.recaptchaToken(),
      },
    }),
    setPasswordForm: createForm({
      submitMethod: 'put',
      submitEndpoint: '/account/password',
      fields: {
        password: commonFields.password(),
        passwordConfirmation: commonFields.passwordConfirmation({}, 'setPasswordForm'),
      },
    }),
    signupForm: createForm({
      submitEndpoint: '/account/request',
      fields: {
        username: createField({
          label: 'Username',
          placeholder: 'poppy',
          required: true,
          validate(value, state) {
            if (!/^[a-z0-9_-]+$/i.test(value)) {
              return 'Username may only include A-Z, 0-9, -, and _'
            }
            if (value.length < 3) {
              return 'Username must be at least 3 characters'
            }
            if (value.length > 32) {
              return 'Username must be less than 32 characters'
            }
            return null
          },
        }),
        email: commonFields.email({
          placeholder: 'poppy@mythicqueststudios.com',
        }),
        companyName: createField({
          label: 'Company or project name',
          placeholder: 'Mythic Quest Studios',
          explicitlyOptional: true,
        }),
        recaptchaToken: commonFields.recaptchaToken(),
      },
    }),
    styleGuideForm: createForm({
      fields: {
        name: createField({
          label: 'Name',
          placeholder: 'Your name',
          required: true,
        }),
        email: commonFields.email({
          note: 'We will definitely <em>NOT</em> send you tons of spam!',
          required: false,
        }),
        age: createField({
          type: 'number',
          label: 'Age',
          required: true,
          placeholder: 'Your age',
          validate(value, state) {
            return Number.parseInt(value, 10) < 18
              ? 'Your age must be at least 18'
              : null
          },
        }),
        favoriteBlockchain: createField({
          type: 'select',
          label: 'Favorite blockchain',
          placeholder: 'Select blockchain',
          required: true,
          options: [
            {
              value: 'avalanche',
              label: 'Avalanche',
              iconUrl: getIconUrl('network', 'avalanche'),
            },
            {
              value: 'binance',
              label: 'Binance',
              iconUrl: getIconUrl('network', 'binance'),
            },
            {
              value: 'ethereum',
              label: 'Ethereum',
              iconUrl: getIconUrl('network', 'ethereum'),
            },
            {
              value: 'perkle',
              label: 'Perkle',
              iconUrl: getIconUrl('network', 'perkle'),
            },
            {
              value: 'polygon',
              label: 'Polygon',
              iconUrl: getIconUrl('network', 'polygon'),
            },
          ],
        }),
        titleAndDescription: createField({
          type: 'select',
          label: 'Title & description',
          placeholder: 'Select title & description',
          required: true,
          options: [
            {
              value: '1',
              label: 'Title of the option',
              description: 'Excepteur sint occaecat cupidatat non proident.',
              iconUrl: null,
            },
            {
              value: '2',
              label: 'Title of the option',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              iconUrl: null,
            },
            {
              value: '3',
              label: 'Title of the option',
              description: 'Officia deserunt mollit anim id est laborum.',
              iconUrl: null,
            },
          ],
        }),
        sectionedAndRich: createField({
          type: 'select',
          label: 'Sectioned & rich',
          placeholder: 'Select a rich option from a section',
          required: true,
          options: [
            {
              optionGroupName: 'MY CONTRACTS',
              value: 'my-contract-1',
              label: 'Shiba Inu Token (SHIB.e)',
              description: 'Avalanche • ERC-20',
              iconUrl: getIconUrl('network-unknown', 'avalanche'),
              descriptionIconUrl: getIconUrl('network', 'avalanche'),
            },
            {
              optionGroupName: 'MY CONTRACTS',
              value: 'my-contract-2',
              label: 'Beeg Brutes WL Pass',
              description: 'Avalanche • ERC-721',
              iconUrl: getIconUrl('network-unknown', 'avalanche'),
              descriptionIconUrl: getIconUrl('network', 'avalanche'),
            },
            {
              optionGroupName: 'MY CONTRACTS',
              value: 'my-contract-3',
              label: 'Tokyo Test Girl (TTG)',
              description: 'Avalanche • Other',
              iconUrl: getIconUrl('network-unknown', 'avalanche'),
              descriptionIconUrl: getIconUrl('network', 'avalanche'),
            },
            {
              optionGroupName: 'MY CONTRACTS',
              value: 'my-contract-4',
              label: 'SPACE ID Name Service (SID)',
              description: 'BNB Smart Chain (BSC) • ERC-721',
              iconUrl: getIconUrl('network-unknown', 'binance'),
              descriptionIconUrl: getIconUrl('network', 'binance'),
            },
            {
              optionGroupName: 'MY CONTRACTS',
              value: 'my-contract-5',
              label: 'SPACE ID Gift Card (SIDGC)',
              description: 'BNB Smart Chain (BSC) • ERC-1155',
              iconUrl: getIconUrl('network-unknown', 'binance'),
              descriptionIconUrl: getIconUrl('network', 'binance'),
            },
            {
              optionGroupName: 'MY CONTRACTS',
              value: 'my-contract-6',
              label: 'Mines of Dalarnia Resources',
              description: 'BNB Smart Chain (BSC) • ERC-1155',
              iconUrl: getIconUrl('network-unknown', 'binance'),
              descriptionIconUrl: getIconUrl('network', 'binance'),
            },
            {
              optionGroupName: 'MY CONTRACTS',
              value: 'my-contract-7',
              label: 'KyberSwap Factory',
              description: 'BNB Smart Chain (BSC) • Other',
              iconUrl: getIconUrl('network-unknown', 'binance'),
              descriptionIconUrl: getIconUrl('network', 'binance'),
            },
            {
              optionGroupName: 'MY CONTRACTS',
              value: 'my-contract-8',
              label: 'Uniswap v3 Positions NFT',
              description: 'Polygon • ERC-721',
              iconUrl: getIconUrl('network-unknown', 'polygon'),
              descriptionIconUrl: getIconUrl('network', 'polygon'),
            },
            {
              optionGroupName: 'MY CONTRACTS',
              value: 'my-contract-9',
              label: 'Skyweaver',
              description: 'Polygon • ERC-1155',
              iconUrl: getIconUrl('network-unknown', 'polygon'),
              descriptionIconUrl: getIconUrl('network', 'polygon'),
            },
            {
              optionGroupName: 'MY CONTRACTS',
              value: 'my-contract-10',
              label: 'a KID called BEAST',
              description: 'Ethereum • ERC-721',
              iconUrl: getIconUrl('network-unknown', 'ethereum'),
              descriptionIconUrl: getIconUrl('network', 'ethereum'),
            },
            {
              optionGroupName: 'MY CONTRACTS',
              value: 'my-contract-11',
              label: 'Mask token',
              description: 'Ethereum • ERC-20',
              iconUrl: getIconUrl('network-unknown', 'ethereum'),
              descriptionIconUrl: getIconUrl('network', 'ethereum'),
            },
            {
              optionGroupName: 'MY CONTRACTS',
              value: 'my-contract-12',
              label: 'Parallel TCG',
              description: 'Ethereum • ERC-1155',
              iconUrl: getIconUrl('network-unknown', 'ethereum'),
              descriptionIconUrl: getIconUrl('network', 'ethereum'),
            },
            {
              optionGroupName: 'MY CONTRACTS',
              value: 'my-contract-13',
              label: 'OpenSea Wyvern Exchange',
              description: 'Ethereum • Other',
              iconUrl: getIconUrl('network-unknown', 'ethereum'),
              descriptionIconUrl: getIconUrl('network', 'ethereum'),
            },
            {
              optionGroupName: 'DISPATCH CONTRACTS',
              value: 'dispatch-contract-1',
              label: 'Bored Ape Yacht Club',
              description: 'Ethereum • ERC-721',
              iconUrl: `${process.env.VUE_APP_DISPATCH_ICONS_BASE_URL}/crypto/ethereum/0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D.svg`,
              descriptionIconUrl: getIconUrl('network', 'ethereum'),
            },
          ],
        }),
        income: createField({
          type: 'select',
          label: 'Income',
          placeholder: 'Select income',
          options: [
            {
              value: '1',
              label: '$0 - $10,000',
              iconUrl: null,
            },
            {
              value: '2',
              label: '$10,000 - $50,000',
              iconUrl: null,
            },
            {
              value: '3',
              label: '$50,000 - $100,000',
              iconUrl: null,
            },
            {
              value: '4',
              label: '$100,000 - $150,000',
              iconUrl: null,
            },
            {
              value: '5',
              label: '$150,000 - $200,000',
              iconUrl: null,
            },
            {
              value: '6',
              label: '$200,000 - $250,000',
              iconUrl: null,
            },
            {
              value: '7',
              label: '$250,000 - $300,000',
              iconUrl: null,
            },
            {
              value: '8',
              label: '+$300,000',
              iconUrl: null,
            },
          ],
        }),
        secrets: createField({
          type: 'textarea',
          label: 'Secrets',
          placeholder: 'Your deepest, darkest secrets',
        }),
        listOfAddresses: createField({
          metaType: 'multi',
          label: 'List of Addresses',
          placeholder: 'Enter address(es) you wish to monitor',
          note: 'Use commas or line breaks when entering multiple addresses',
          validate: (value) => { return validateAddress(value, true) },
          isAtLeastOneMultiEntryValid: (value) => {
            return (value || '').split(',').some((address) => {
              return validateAddress(address.trim()) === null
            })
          },
          format: formatAddress,
        }),
        checkboxAgree: createField({
          type: 'checkbox',
          label: 'Checkbox',
          placeholder: 'Yes, I agree to test a checkbox',
          required: true,
          value: false,
        }),
        disabledCheckbox: createField({
          type: 'checkbox',
          label: 'Disabled checkbox',
          placeholder: 'You can\'t click me!',
          disabled: true,
          value: false,
        }),
        multiCheckbox: createField({
          type: 'checkbox',
          metaType: 'multi',
          label: 'Multi-Checkbox',
          options: [
            {
              id: 'alpha',
              value: 'alpha',
              label: 'Alpha',
              iconUrl: null,
            },
            {
              id: 'alpha',
              value: 'beta',
              label: 'Beta',
              iconUrl: null,
            },
            {
              id: 'alpha',
              value: 'gamma',
              label: 'Gamma',
              iconUrl: null,
            },
            {
              id: 'alpha',
              value: 'delta',
              label: 'Delta',
              iconUrl: null,
            },
            {
              id: 'alpha',
              value: 'epsilon',
              label: 'Epsilon',
              iconUrl: null,
            },
            {
              id: 'alpha',
              value: 'zeta',
              label: 'Zeta',
              iconUrl: null,
            },
          ],
          required: true,
          value: [],
          validate(value, state) {
            if (value.includes('beta')) {
              return 'Please do not choose the "Beta" option'
            }
            return null
          },
        }),
        contractEventChecklist: createField({
          type: 'checkbox',
          metaType: 'multi',
          label: 'Rich Multi-Checkbox (with "Contract Events" Styling)',
          labelDescription: 'Select all the events you want to use as Triggers',
          options: [
            {
              id: 'AllowedRecipientChanged(address indexed _recipient, bool indexed _allowed)',
              value: 'AllowedRecipientChanged(address indexed _recipient, bool indexed _allowed)',
              description: 'address indexed _recipient, bool indexed _allowed',
              label: 'AllowedRecipientChanged',
              iconUrl: null,
            },
            {
              id: 'Approval(address _owner, address _spender, uint256 _amount)',
              value: 'Approval(address _owner, address _spender, uint256 _amount)',
              description: 'address _owner, address _spender, uint256 _amount',
              label: 'Approval',
              iconUrl: null,
            },
            {
              id: 'CreatedToken(address to, uint256 amount)',
              value: 'CreatedToken(address to, uint256 amount)',
              description: 'address to, uint256 amount',
              label: 'CreatedToken',
              iconUrl: null,
            },
            {
              id: 'FuelingToDate(uint256 value)',
              value: 'FuelingToDate(uint256 value)',
              description: 'uint256 value',
              label: 'FuelingToDate',
              iconUrl: null,
            },
            {
              id: 'NewCurator(address _newCurator)',
              value: 'NewCurator(address _newCurator)',
              description: 'address _newCurator',
              label: 'NewCurator',
              iconUrl: null,
            },
            {
              id: 'ProposalAdded(uint256 proposalID, address recipient, uint256 amount, bool newCurator, string description)',
              value: 'ProposalAdded(uint256 proposalID, address recipient, uint256 amount, bool newCurator, string description)',
              description: 'uint256 proposalID, address recipient, uint256 amount, bool newCurator, string description',
              label: 'ProposalAdded',
              iconUrl: null,
            },
            {
              id: 'ProposalTallied(uint256 proposalID, bool result, uint256 quorum)',
              value: 'ProposalTallied(uint256 proposalID, bool result, uint256 quorum)',
              description: 'uint256 proposalID, bool result, uint256 quorum',
              label: 'ProposalTallied',
              iconUrl: null,
            },
            {
              id: 'Refund(address to, uint256 value)',
              value: 'Refund(address to, uint256 value)',
              description: 'address to, uint256 value',
              label: 'Refund',
              iconUrl: null,
            },
            {
              id: 'Transfer(address _from, address _to, uint256 _amount)',
              value: 'Transfer(address _from, address _to, uint256 _amount)',
              description: 'address _from, address _to, uint256 _amount',
              label: 'Transfer',
              iconUrl: null,
            },
            {
              id: 'Voted(uint256 proposalID, bool position, address voter)',
              value: 'Voted(uint256 proposalID, bool position, address voter)',
              description: 'uint256 proposalID, bool position, address voter',
              label: 'Voted',
              iconUrl: null,
            },
          ],
          required: true,
          value: [],
          validate(value, state) {
            if (value.includes('NewCurator(address _newCurator)')) {
              return 'Please do not choose the "NewCurator" event'
            }
            return null
          },
        }),
        breathCount: createField({
          type: 'radio',
          label: 'How many times a day do you breath?',
          options: [
            {
              id: '5,000-10,000',
              value: '5,000-10,000',
              label: '5,000-10,000',
              iconUrl: null,
            },
            {
              id: '10,000-15,000',
              value: '10,000-15,000',
              label: '10,000-15,000',
              iconUrl: null,
            },
            {
              id: '15,000-20,000',
              value: '15,000-20,000',
              label: '15,000-20,000',
              iconUrl: null,
            },
            {
              id: '20,000-25,000',
              value: '20,000-25,000',
              label: '20,000-25,000',
              iconUrl: null,
            },
          ],
          required: true,
        }),
        timerange: createField({
          type: 'radio',
          metaType: 'timerange',
          label: 'Timerange',
          options: [
            {
              id: '1h',
              value: '3600000',
              label: '1h',
              iconUrl: null,
            },
            {
              id: '24h',
              value: '86400000',
              label: '24h',
              iconUrl: null,
            },
            {
              id: '7d',
              value: '604800000',
              label: '7d',
              iconUrl: null,
            },
            {
              id: '30d',
              value: '2592000000',
              label: '30d',
              iconUrl: null,
            },
            {
              id: '1y',
              value: '31536000000',
              label: '1y',
              iconUrl: null,
            },
          ],
          required: true,
        }),
        contentSwitcher: createField({
          type: 'radio',
          value: 'monthly',
          options: [
            {
              id: 'monthly',
              value: 'monthly',
              label: 'Monthly',
              iconUrl: null,
              accentText: null,
            },
            {
              id: 'yearly',
              value: 'yearly',
              label: 'Yearly',
              iconUrl: null,
              accentText: '- Save 15%',
            },
          ],
        }),
        enablePartyMode: createField({
          type: 'checkbox',
          label: 'Enable Party Mode?',
          placeholder: 'Yes, enable party mode',
          value: false,
        }),
      },
    }),
  }
}

export { getDefaultState }

export default getDefaultState()
