const getDefaultState = () => {
  return {
    user: null,
    userPlan: null,
    userFlags: null,
    userPlanStats: null,
    isUserDataLoading: true,

    userAPIKeys: [],
    userContracts: [],
    accountIntegrations: [],

    userContractOptionsContractIdMap: {},
    networkUserContractOptionsSlugMap: {},

    authToken: null,
    refreshToken: null,
    authTokenExpiry: null,
    refreshTokenExpiry: null,
  }
}

export { getDefaultState }

export default getDefaultState()
