import { snakeCase } from 'snake-case'

import { sortByName } from '@/utils'

const userPaginationLists = [
  'userPatches',
  'userPatchEvents',
  'dispatchMonitorEvents',

  'userContracts',
  'userContractEvents',
]

export default {

  GET_USER_DATA({ rootState, commit, dispatch }) {

    commit('SET_IS_USER_DATA_LOADING', true)

    // we always want to load all api keys...
    const params = {
      size: 2 ** 32,
    }

    return Promise
      .all([
        rootState.api.dispatch.get('/account'),
        rootState.api.dispatch.get('/account/tokens', { params }),
      ])
      .then(([user, apiKeys]) => {

        const userData = {
          user: user.data,
          apiKeys: apiKeys.data,
        }

        commit('SET_USER', userData.user)
        commit('SET_USER_FLAGS', userData.user.flags)
        commit('SET_USER_API_KEYS', userData.apiKeys)

        return userData

      })
      .then(() => {
        return Promise.all([
          dispatch('REFRESH_USER_PLAN'),
          dispatch('REFRESH_USER_CONTRACTS'),
          dispatch('REFRESH_USER_PLAN_STATS'),
          dispatch('REFRESH_ACCOUNT_INTEGRATIONS'),
        ])
      })

      // load all paginated user lists
      .then(() => {
        return Promise.all(userPaginationLists.map((listName) => {
          return dispatch('pagination/RESET_PAGINATION', { listName }, { root: true })
        }))
      })

      .then(() => {
        commit('SET_IS_USER_DATA_LOADING', false)
      })

  },

  UPDATE_AUTH_TOKEN({ commit }, newAuthTokenData) {

    const {

      // these are required
      newAuthToken,
      newAuthTokenExpiry,

      // these are optional
      newRefreshToken = null,
      newRefreshTokenExpiry = null,

    } = newAuthTokenData

    commit('SET_AUTH_TOKEN', newAuthToken)
    commit('SET_AUTH_TOKEN_EXPIRY', newAuthTokenExpiry)
    commit('api/SET_DISPATCH_AUTH_TOKEN', newAuthToken, { root: true })

    commit('SET_REFRESH_TOKEN', newRefreshToken)
    commit('SET_REFRESH_TOKEN_EXPIRY', newRefreshTokenExpiry)

  },

  UPDATE_USER_FLAGS({ dispatch, rootState }, newFlags) {

    const promises = Object
      .keys(newFlags)
      .map((flagName) => {
        const newFlagValue = newFlags[flagName]
        return rootState.api.dispatch[newFlagValue ? 'put' : 'delete'](`/account/flags/${snakeCase(flagName)}`)
      })

    return Promise.all(promises)
      .finally(() => {
        dispatch('REFRESH_USER_FLAGS')
      })

  },

  REFRESH_USER_FLAGS({ commit, rootState }) {
    return rootState.api.dispatch.get('/account/flags')
      .then((response) => {
        commit('SET_USER_FLAGS', response.data)
      })
  },

  REFRESH_USER_PLAN_STATS({ commit, rootState }) {
    return rootState.api.dispatch.get('/account/plan/usage')
      .then((response) => {
        commit('SET_USER_PLAN_STATS', response.data)
      })
  },

  REFRESH_ACCOUNT_INTEGRATIONS({ commit, dispatch, rootState }, selectedAccountIntegration = null) {

    return rootState.api.dispatch.get('/integrations', { params: { size: 2 ** 32 } })
      .then((response) => {

        const accountIntegrations = response.data.filter((accountIntegration) => {
          return accountIntegration.isVerified === true
        })

        commit('SET_ACCOUNT_INTEGRATIONS', accountIntegrations)

        return Promise.all([
          dispatch('forms/REFRESH_TELEGRAM_DESTINATION_OPTIONS', '', { root: true }),
          dispatch('forms/REFRESH_DISCORD_DESTINATION_OPTIONS', '', { root: true }),
          dispatch('forms/REFRESH_ACCOUNT_WEBHOOK_OPTIONS', '', { root: true }),
        ])

      })
      .then(() => {

        if (!selectedAccountIntegration) return null

        switch (selectedAccountIntegration.provider) {
          case 'telegram': return dispatch('forms/REFRESH_TELEGRAM_DESTINATION_OPTIONS', selectedAccountIntegration.id, { root: true })
          case 'discord': return dispatch('forms/REFRESH_DISCORD_DESTINATION_OPTIONS', selectedAccountIntegration.id, { root: true })
          case 'webhook': return dispatch('forms/REFRESH_ACCOUNT_WEBHOOK_OPTIONS', selectedAccountIntegration.id, { root: true })
          default: return null // do nothing
        }

      })

  },

  REFRESH_USER_CONTRACTS({ commit, dispatch, rootState }) {

    const optionMaps = {
      userContractOptionsContractIdMap: {},
      networkUserContractOptionsSlugMap: {},
    }

    rootState.app.networkOptions.forEach((networkOption) => {
      optionMaps.networkUserContractOptionsSlugMap[networkOption.apiRecord.slug] = []
    })

    return rootState.api.dispatch.get('/account/contracts', { params: { size: 2 ** 32 } })
      .then((response) => {

        const userContracts = response.data.sort(sortByName)

        userContracts.forEach((contract) => {

          const networkOption = rootState.app.networkOptionsIdMap[contract.networkId]

          // this should never really happen, unless we deactivate some networks
          if (!networkOption) return

          const network = networkOption.apiRecord

          // @NOTE: we don't currently populate contract "symbols" for
          //  user-created contracts (since that must be read from the contract
          //  and not from block explorer APIs), but do we still want the symbol
          //  for user contracts if it exists? 🤔
          const label = contract.symbol && (contract.type === 'base' || contract.type === 'erc-20' || contract.type === 'bep-20')
            ? `${contract.name} - ${contract.symbol}`
            : contract.name

          const userContractOption = {
            label,
            id: contract.id,
            value: contract.id,
            apiRecord: contract,
            iconUrl: contract.iconUrl,
            optionGroupName: 'MY CONTRACTS',
            descriptionIconUrl: network.iconUrl,
            description: `${network.name} • ${contract.type.toUpperCase()}`,
          }

          optionMaps.networkUserContractOptionsSlugMap[network.slug] ||= []

          optionMaps.userContractOptionsContractIdMap[contract.id] = userContractOption
          optionMaps.networkUserContractOptionsSlugMap[network.slug].push(userContractOption)

        })

        commit('SET_USER_CONTRACTS', response.data)
        commit('SET_USER_CONTRACT_OPTION_MAPS', optionMaps)

      })

  },

  REFRESH_USER_PLAN({ state, commit, dispatch, rootState }) {

    return rootState.api.dispatch.get('/account/plan')
      .then((response) => {

        let stripeSubscription = null
        let stripeSubscriptionItem = null
        let stripePrice = rootState.app.stripePricesSlugMap.free
        let stripeProduct = rootState.app.stripePlansSlugMap.free

        // if the user's subscription is canceled (i.e. they downgraded to free
        //  or didn't pay their subscription invoice), save that "elsewhere"
        //  since we use stripeSubscription to indicate the user's current plan
        //  (which is the free plan in this case)
        let canceledStripePrice = null
        let canceledStripeProduct = null
        let canceledStripeSubscription = null

        if (
          typeof response.data === 'object'
          && typeof response.data.stripe === 'object'
          && typeof response.data.stripe.subscription === 'object'
          && response.data.stripe.subscription.status !== 'incomplete'
          && response.data.stripe.subscription.status !== 'incomplete_expired'
        ) {

          // possible subscription statuses are incomplete, incomplete_expired,
          //  trialing, active, past_due, canceled, unpaid, or paused.
          //
          // @TODO: what should happen when a subscription is "paused" 🤔
          //
          // see: https://docs.stripe.com/api/subscriptions/object#subscription_object-status
          const { status } = response.data.stripe.subscription

          if (status === 'canceled' || status === 'unpaid') {

            canceledStripeSubscription = response.data.stripe.subscription

            // @NOTE: we don't really need to reference canceledStripeSubscriptionItem
            //  anywhere else so we define it in this scope instead of above
            const canceledStripeSubscriptionItem = canceledStripeSubscription.items.data.find((item) => {
              return item.object === 'subscription_item'
            }) || null

            if (canceledStripeSubscriptionItem) {
              canceledStripePrice = rootState.app.stripePricesIdMap[canceledStripeSubscriptionItem.price.id]
              canceledStripeProduct = rootState.app.stripePlansIdMap[canceledStripeSubscriptionItem.price.product]
            }

          // trialing, active, past_due, paused...
          } else {

            stripeSubscription = response.data.stripe.subscription

            // @NOTE: stripeSubscriptionItem is NOT the same thing as
            //  stripeSubscription, it's a totally separate object that represents
            //  one "line item" in the subscription
            //
            // however, this should always be a 1:1 relationship since we don't
            //  have any subscription models with multiple items in them...
            stripeSubscriptionItem = stripeSubscription.items.data.find((item) => {
              return item.object === 'subscription_item'
            }) || null

            if (stripeSubscriptionItem) {
              stripePrice = rootState.app.stripePricesIdMap[stripeSubscriptionItem.price.id]
              stripeProduct = rootState.app.stripePlansIdMap[stripeSubscriptionItem.price.product]
            }

          }

        }

        const userPlan = {
          apiRecord: response.data,

          stripePrice,
          stripeProduct,
          stripeSubscription,
          stripeSubscriptionItem,
          stripeCustomerId: response.data.customerId,

          canceledStripePrice,
          canceledStripeProduct,
          canceledStripeSubscription,

          name: stripeProduct.name,
          description: stripeProduct.description,
          slug: stripeProduct.metadata.productSlug,
          tier: Number.parseInt(stripeProduct.metadata.planTier, 10),

          billingInterval: null,
          subscriptionPeriodEndsAt: null,
          subscriptionPeriodStartsAt: null,

          actionEventLimit: Number.parseInt(stripeProduct.metadata.maxEventsMonthly, 10),
          maxEventsTimeframe: Number.parseInt(stripeProduct.metadata.maxEventsTimeframe, 10),
        }

        if (stripeSubscription) {
          userPlan.subscriptionPeriodEndsAt = new Date(stripeSubscription.currentPeriodEnd * 1000)
          userPlan.subscriptionPeriodStartsAt = new Date(stripeSubscription.currentPeriodStart * 1000)
        }

        userPlan.actionEventLimit = Math.max(userPlan.actionEventLimit, response.data.credits)

        if (userPlan.slug !== 'free' && stripePrice && stripePrice.recurring) {

          userPlan.billingInterval = stripePrice.recurring.interval

          // @NOTE: on staging we use days instead of months / years for
          //  subscriptions for easier testing, so let's kinda of "alias" those
          //  here so all the month/year logic works correctly
          if (process.env.VUE_APP_ENV !== 'production' && userPlan.billingInterval === 'day') {
            userPlan.billingInterval = stripePrice.recurring.intervalCount === 1 ? 'month' : 'year'
          }

        }

        commit('SET_USER_PLAN', userPlan)

      })

      // disable all timerange options that are not available based on the
      //  user's plan
      .then(() => {

        return userPaginationLists.forEach((listName) => {

          const { filterFormName } = rootState.pagination[listName]
          const userPaginationForm = rootState.forms[filterFormName]

          Object.keys(userPaginationForm.fields).forEach((fieldName) => {

            const formField = userPaginationForm.fields[fieldName]

            if (formField.metaType !== 'timerange') return
            if (!formField.options) return

            const newOptions = Array.from(formField.options)

            newOptions.forEach((option) => {

              option.disabled = option.value > rootState.user.userPlan.maxEventsTimeframe

              // also set the highest available timerange as the default
              //  selected option
              if (!option.disabled) {
                commit('forms/SET_FIELD_VALUE', {
                  formName: filterFormName,
                  fieldName,
                  newValue: option.value,
                }, { root: true })
              }

            })

            commit('forms/SET_FIELD_OPTIONS', {
              formName: filterFormName,
              fieldName,
              newOptions,
            }, { root: true })

          })

        })

      })

  },

}
