<template>
  <header v-if="title">
    <h5>{{ title }}</h5>
  </header>
  <main>
    <slot />
  </main>
  <footer>
    <template v-if="showBackButton">
      <button
        @click="onBack"
        class="link small back-button"
        :disabled="isLoading || disableBackButton"
      >
        {{ backTitle }}
      </button>
      <span class="flex-grow" />
    </template>
    <button
      @click="onCancel"
      class="tertiary small"
      v-if="showCancelButton"
      :disabled="isLoading || disableCancelButton"
    >
      {{ cancelTitle }}
    </button>
    <button
      class="small"
      @click="onAction"
      v-if="showActionButton"
      :class="actionButtonClasses"
      :disabled="isLoading || disableActionButton"
    >
      {{ actionTitle }}
    </button>
  </footer>
</template>

<script>

  export default {
    props: {
      title: {
        type: String,
        default: null,
      },
      cancelTitle: {
        type: String,
        default: 'Cancel',
      },
      actionTitle: {
        type: String,
        default: 'Okay',
      },
      backTitle: {
        type: String,
        default: 'Back',
      },
      cancelAction: {
        type: Function,
        default: () => {},
      },
      action: {
        type: Function,
        default: () => {},
      },
      backAction: {
        type: Function,
        default: () => {},
      },
      closeOnAction: {
        type: Boolean,
        default: true,
      },
      showCancelButton: {
        type: Boolean,
        default: true,
      },
      showActionButton: {
        type: Boolean,
        default: true,
      },
      showBackButton: {
        type: Boolean,
        default: false,
      },
      actionButtonClasses: {
        type: Array,
        default: () => { return [] },
      },
      disableCancelButton: {
        type: Boolean,
        default: false,
      },
      disableActionButton: {
        type: Boolean,
        default: false,
      },
      disableBackButton: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isLoading: false,
      }
    },
    mounted() {
      document.addEventListener('keydown', this.onKeyDown)
    },
    beforeUnmount() {
      document.removeEventListener('keydown', this.onKeyDown)
    },
    methods: {
      onKeyDown(event) {
        if (
          !event.target
          || event.code !== 'Enter'
          || (this.isLoading || this.disableActionButton)
          || (event.target.tagName !== 'INPUT' && event.target.tagName !== 'SELECT')
        ) return
        this.onAction()
      },
      onCancel() {
        if (typeof this.cancelAction === 'function') this.cancelAction()
        this.close()
      },
      onBack() {
        if (typeof this.backAction === 'function') this.backAction()
      },
      onAction() {

        if (typeof this.action !== 'function') {
          if (this.closeOnAction) this.close()
          return null
        }

        this.isLoading = true
        this.$store.commit('modals/SET_ALLOW_MODAL_CLOSE', false)

        return Promise.resolve(this.action())
          .finally(() => {
            this.isLoading = false
            this.$store.commit('modals/SET_ALLOW_MODAL_CLOSE', true)
            if (this.closeOnAction) this.close()
          })

      },
      close() {
        this.$store.dispatch('modals/CLOSE_MODAL')
      },
    },
  }

</script>

<style lang="stylus" scoped>

  footer:not(:empty)
    @apply mt-6
    @apply flex
    @apply space-x-2
    @apply justify-end

  .back-button
    @apply text-gray-800

    &:hover:not([disabled])
      @apply text-gray-800

    &:active:not([disabled])
      @apply text-gray-1000

</style>
