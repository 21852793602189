import { createStore } from 'vuex'

import api from './api'
import app from './app'
import user from './user'
import toast from './toast'
import forms from './forms'
import modals from './modals'
import pagination from './pagination'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    api,
    app,
    user,
    toast,
    forms,
    modals,
    pagination,
  },
})
