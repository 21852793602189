import axios from 'axios'

export default () => {
  return {
    dispatch: axios.create({
      baseURL: `${process.env.VUE_APP_DISPATCH_API_BASE_URL}/v1`,
      headers: {
        Accept: 'application/json',
        'X-Request-Format': 'camelCase',
        'X-Response-Format': 'camelCase',
        'Content-Type': 'application/json',
      },
    }),

    admin: axios.create({
      baseURL: `${process.env.VUE_APP_DISPATCH_API_BASE_URL}/v1/admin`,
      headers: {
        Accept: 'application/json',
        'X-Request-Format': 'camelCase',
        'X-Response-Format': 'camelCase',
        'Content-Type': 'application/json',
      },
    }),

    hubspot: axios.create({
      baseURL: `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.VUE_APP_HUBSPOT_PORTAL_ID}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }),

    // sometimes the API can throw an error when the user is following an email
    //  link (e.g. the button in the account confirmation email) and instead of
    //  showing the user a raw JSON response from the API, it will redirect to
    //  the /error route on the frontend with one of these codes
    //
    // (see src/views/Error.vue for details)
    dispatchAPIErrors: {
      unknown_error: 'An unknown error occurred. Please try again later.',
      account_already_confirmed: 'This email address has already been confirmed. Please login instead.',
      invitation_not_found: 'There was a problem confirming your email address. Please contact Esprezzo support at support@esprezzo.io for help.',
      invalid_verification_token: 'There was a problem resetting your password. Please contact Esprezzo support at support@esprezzo.io for help.',
    },
  }
}
