export default {
  getFieldError(state) {
    // @NOTE: valueOverride is useful if we want to test an arbitrary value
    //  using the same validation logic for a given field (e.g. when applying
    //  the displayValue to the actual value for multi-text inputs)
    return (formName, fieldName, valueOverride = undefined) => {

      const form = state[formName]
      const field = form.fields[fieldName]

      let value = valueOverride

      if (typeof valueOverride === 'undefined') {
        value = typeof field.displayValue === 'string'
          ? field.displayValue
          : field.value
      }

      const isArray = Array.isArray(value)
      const isEmpty = isArray
        ? value.length === 0
        : !value

      let newError = null

      if (isEmpty) {
        if (!field.required) return null
        newError = 'This field is required'

      } else if (field.type === 'number' && !!value && Number.isNaN(Number.parseInt(value, 10))) {
        newError = 'This field must be a number'

      } else if (typeof field.validate === 'function') {

        if (isArray) {
          value.some((item) => {
            newError = field.validate(item, state)
            return newError !== null
          })

        } else {
          newError = field.validate(value, state)
        }

      }

      return newError

    }
  },
  isFieldValid(state, getters) {
    return (formName, fieldName) => {

      const form = state[formName]
      const field = form.fields[fieldName]

      // don't consider a field invalid if there are invalidMultiValues entries,
      //  as this value is really only there to show the user which entries will
      //  not be added when the form is submitted...
      //
      // if (field.invalidMultiValues.length !== 0) {
      //   return false
      // }

      // if the field uses a displayValue, make sure to validate the actual
      //  value and not the displayValue here (the displayValue validation is
      //  handled separately by the form component...)
      if (typeof field.displayValue === 'string') {
        return getters.getFieldError(formName, fieldName, field.value) === null
      }

      return getters.getFieldError(formName, fieldName) === null

    }
  },
  isFormValid(state, getters) {
    return (formName) => {
      return Object
        .keys(state[formName].fields)
        .every((fieldName) => {
          return getters.isFieldValid(formName, fieldName)
        })
    }
  },
  hasErrors(state) {
    return (formName) => {
      return Object.keys(state[formName].fields).some((key) => {
        return !!state[formName].fields[key].error
      })
    }
  },
}
