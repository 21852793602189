import { getIconUrl } from '@/composables/useIcons'

export default {

  UPDATE_FIELD({ state, commit, dispatch }, newData) {

    // required
    const { formName, fieldName } = newData

    // optional
    const { newValue, newPlaceholder, newNote, newLabel, newDisplayValue, newInvalidMultiValues } = newData

    // optional with defaults if unspecified
    const { newIsDirty = true, newError = null, shouldValidate = true } = newData

    if (Object.hasOwnProperty.call(newData, 'newValue')) {
      commit('SET_FIELD_VALUE', {
        newValue,
        fieldName,
        formName,
      })
    }

    if (Object.hasOwnProperty.call(newData, 'newDisplayValue')) {
      commit('SET_FIELD_DISPLAY_VALUE', {
        newValue: newDisplayValue,
        fieldName,
        formName,
      })
    }

    if (Object.hasOwnProperty.call(newData, 'newInvalidMultiValues')) {
      commit('SET_FIELD_INVALID_MULTI_VALUES', {
        newValue: newInvalidMultiValues,
        fieldName,
        formName,
      })
    }

    if (Object.hasOwnProperty.call(newData, 'newPlaceholder')) {
      commit('SET_FIELD_PLACEHOLDER', {
        newValue: newPlaceholder,
        fieldName,
        formName,
      })
    }

    if (Object.hasOwnProperty.call(newData, 'newLabel')) {
      commit('SET_FIELD_LABEL', {
        newValue: newLabel,
        fieldName,
        formName,
      })
    }

    if (Object.hasOwnProperty.call(newData, 'newNote')) {
      commit('SET_FIELD_NOTE', {
        newValue: newNote,
        fieldName,
        formName,
      })
    }

    commit('SET_FIELD_IS_DIRTY', {
      newValue: newIsDirty,
      fieldName,
      formName,
    })

    commit('SET_FIELD_ERROR', {
      fieldName,
      formName,
      newError,
    })

    if (shouldValidate) {
      return dispatch('VALIDATE_FIELD', { formName, fieldName })
    }

    return null

  },

  VALIDATE_FIELD({ state, commit, getters }, { formName, fieldName }) {
    const newError = getters.getFieldError(formName, fieldName)
    commit('SET_FIELD_ERROR', { formName, fieldName, newError })
    return Promise.resolve(newError === null)
  },

  GET_FORM_DATA(context, formName) {
    return context.state[formName].getFormData(context.state[formName], context)
  },

  SUBMIT_FORM(context, formName) {

    const { state, rootState, commit } = context

    const form = state[formName]
    const formData = form.getFormData(form, context)

    const submitMethod = form.submitMethod || 'post'
    const submitEndpoint = typeof form.submitEndpoint === 'function'
      ? form.submitEndpoint(form.submitEndpointVariables)
      : form.submitEndpoint

    const apiType = form.apiType || 'dispatch'

    commit('SET_IS_LOADING', { formName, newValue: true })
    commit('SET_FORM_SUBMIT_ERRORS', { formName, newErrors: [] })

    return rootState.api[apiType][submitMethod](submitEndpoint, formData)
      .then((result) => {
        commit('SET_IS_SUBMITTED', { formName, newValue: true })
        return result
      })
      .catch((error) => {

        let newErrors = [error.message]

        if (!error.response || !error.response.data) {
          newErrors[0] = rootState.api.dispatchAPIErrors.unknown_error

        } else if (form.apiType === 'hubspot') {
          newErrors[0] = error.response.data.message

        } else if (form.apiType === 'dispatch' || form.apiType === 'admin') {
          if (Array.isArray(error.response.data)) {
            newErrors = error.response.data
          } else {
            newErrors[0] = error.response.data
          }
        }

        commit('SET_FORM_SUBMIT_ERRORS', { formName, newErrors })

        throw error

      })
      .finally(() => {
        commit('SET_IS_LOADING', { formName, newValue: false })
      })

  },

  REFRESH_TELEGRAM_DESTINATION_OPTIONS({ state, rootState, rootGetters, commit }, newValue = '') {

    const form = state.createPatchForm
    const telegramChatType = form.fields.telegramActionEvent.value

    if (!telegramChatType) return Promise.resolve()

    const accountIntegrations = telegramChatType === 'private'
      ? rootGetters['user/telegramPrivateChats']
      : rootGetters['user/telegramGroupChats']

    const newOptions = []

    accountIntegrations.forEach((accountIntegration) => {
      newOptions.push({
        value: accountIntegration.id,
        apiRecord: accountIntegration,
        label: accountIntegration.name,
        iconUrl: getIconUrl('action', 'telegram'),
      })
    })

    const label = telegramChatType === 'private'
      ? 'Click here to add a Telegram account'
      : 'Click here to add a Telegram group'

    newOptions.push({
      label,
      value: 'add-new-account',
      showExternalLinkIcon: true,
      iconUrl: getIconUrl('action', 'telegram'),
    })

    commit('SET_FIELD_OPTIONS', {
      newOptions,
      formName: 'createPatchForm',
      fieldName: 'telegramAccountIntegrationId',
    })

    // @NOTE: there was previously a bug here, where refreshing the
    //  telegramAccountIntegrationId "options" but NOT changing the
    //  telegramAccountIntegrationId "value" would cause the
    //  "selectedTelegramIntegrationOption" watcher in the create patch form to
    //  re-trigger the "add bot" Telegram splash page because the value was
    //  never changed form "add-new-account" but the watcher fires because the
    //  list is replaced with new options
    //
    // so we should just always reset the telegramAccountIntegrationId value to
    //  something OTHER than the "add-new-account" option
    commit('SET_FIELD_VALUE', {
      newValue,
      formName: 'createPatchForm',
      fieldName: 'telegramAccountIntegrationId',
    })

    return Promise.resolve()

  },

  REFRESH_DISCORD_DESTINATION_OPTIONS({ state, rootState, rootGetters, commit, dispatch }, newValue = '') {

    const form = state.createPatchForm
    const discordChatType = form.fields.discordActionEvent.value

    if (!discordChatType) return Promise.resolve()

    const accountIntegrations = discordChatType === 'server-channel'
      ? rootGetters['user/discordServers']
      : rootGetters['user/discordDMs']

    const newOptions = []

    accountIntegrations.forEach((accountIntegration) => {
      newOptions.push({
        value: accountIntegration.id,
        apiRecord: accountIntegration,
        label: accountIntegration.name,
        iconUrl: getIconUrl('action', 'discord'),
      })
    })

    const label = discordChatType === 'server-channel'
      ? 'Click here to add a Discord server'
      : 'Click here to add a Discord user'

    newOptions.push({
      label,
      value: 'add-new-account',
      showExternalLinkIcon: true,
      iconUrl: getIconUrl('action', 'discord'),
    })

    commit('SET_FIELD_OPTIONS', {
      newOptions,
      formName: 'createPatchForm',
      fieldName: 'discordAccountIntegrationId',
    })

    // always set the field value, see the note in
    //  REFRESH_TELEGRAM_DESTINATION_OPTIONS above
    commit('SET_FIELD_VALUE', {
      newValue,
      formName: 'createPatchForm',
      fieldName: 'discordAccountIntegrationId',
    })

    if (discordChatType === 'server-channel') {
      return dispatch('REFRESH_DISCORD_SERVER_CHANNEL_OPTIONS')
    }

    return Promise.resolve()

  },

  REFRESH_DISCORD_SERVER_CHANNEL_OPTIONS({ state, rootState, commit }, newValue = '') {

    const formName = 'createPatchForm'
    const fieldName = 'discordChannelId'
    const accountIntegrationId = state[formName].fields.discordAccountIntegrationId.value

    if (!accountIntegrationId) return Promise.resolve()

    commit('SET_FIELD_IS_LOADING', {
      formName,
      fieldName,
      newValue: true,
    })

    return rootState.api.dispatch.get(`/integrations/${accountIntegrationId}/channels`)
      .then((response) => {

        let isNewValueInOptions = false

        const newOptions = response.data.map((channel) => {

          isNewValueInOptions ||= newValue === channel.id

          return {
            value: channel.id,
            apiRecord: channel,
            label: `#${channel.name}`,
            iconUrl: getIconUrl('action', 'discord'),
          }

        })

        commit('SET_FIELD_OPTIONS', {
          formName,
          fieldName,
          newOptions,
        })

        commit('SET_FIELD_VALUE', {
          formName,
          fieldName,
          newValue: isNewValueInOptions ? newValue : '',
        })

        commit('SET_FIELD_IS_LOADING', {
          formName,
          fieldName,
          newValue: false,
        })

      })
      .catch((error) => {

        commit('SET_FIELD_IS_LOADING', {
          formName,
          fieldName,
          newValue: false,
        })

        throw error

      })

  },

  REFRESH_ACCOUNT_WEBHOOK_OPTIONS({ state, rootState, rootGetters, commit, dispatch }, newValue = '') {

    const accountIntegrations = rootGetters['user/accountWebhooks']

    const newOptions = []

    accountIntegrations.forEach((accountIntegration) => {
      newOptions.push({
        value: accountIntegration.id,
        apiRecord: accountIntegration,
        label: accountIntegration.name,
        iconUrl: getIconUrl('action', 'webhook'),
      })
    })

    newOptions.push({
      value: 'add-new-account',
      label: 'Click here to add a new webhook',
      iconUrl: getIconUrl('action', 'webhook'),
    })

    commit('SET_FIELD_OPTIONS', {
      newOptions,
      formName: 'createPatchForm',
      fieldName: 'webhookAccountIntegrationId',
    })

    // always set the field value, see the note in
    //  REFRESH_TELEGRAM_DESTINATION_OPTIONS above
    commit('SET_FIELD_VALUE', {
      newValue,
      formName: 'createPatchForm',
      fieldName: 'webhookAccountIntegrationId',
    })

    return Promise.resolve()

  },

}
