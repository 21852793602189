export default {

  CREATE_TOAST({ state, commit }, { text, type = 'info', timeout = 10000, clickHandler }) {

    const newToast = {
      text,
      type,
      timeout,
      clickHandler,
      close: () => {
        commit('REMOVE_TOAST', newToast)
      },
    }

    commit('ADD_TOAST', newToast)

    if (timeout) {
      setTimeout(() => {
        commit('REMOVE_TOAST', newToast)
      }, timeout)
    }

  },

}
