export default {
  getPatchEventRowIconUrl(state) {
    return (patch) => {

      switch (patch.trigger.type) {
        case 'nft':
        case 'user-contract':
        case 'balance-change':
          return (patch.contract && patch.contract.iconUrl) ? patch.contract.iconUrl : `${process.env.VUE_APP_DISPATCH_ICONS_BASE_URL}/unknown.svg`

        default:
          return patch.trigger.iconUrl ? patch.trigger.iconUrl : `${process.env.VUE_APP_DISPATCH_ICONS_BASE_URL}/unknown.svg`
      }

    }
  },
  // @NOTE: these three methods are for retrieving patch settings from a patch
  //  or patch event that has already been created (to retrieve the saved values
  //  for each setting)
  getPatchSettingBySlug(state) {
    return (patchOrPatchEvent, settingSlug, settingType) => {
      const matchedSetting = patchOrPatchEvent.settings.find((setting) => {
        return (
          setting.setting.slug === settingSlug
          && setting.setting.type === settingType
        )
      })
      return matchedSetting || {}
    }
  },
  getActionSettingBySlug(state, getters) {
    return (patch, settingSlug) => {
      return getters.getPatchSettingBySlug(patch, settingSlug, 'action')
    }
  },
  getTriggerSettingBySlug(state, getters) {
    return (patch, settingSlug) => {
      return getters.getPatchSettingBySlug(patch, settingSlug, 'trigger')
    }
  },
}
