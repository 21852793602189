import { getDefaultState } from './state'

export default {
  RESET_STATE(currentState) {
    Object.assign(currentState, getDefaultState())
  },
  SET_QUERY(currentState, { listName, query }) {
    currentState[listName].query = query
  },
  SET_FILTERS(currentState, { listName, filters }) {
    currentState[listName].filters = filters
  },
  SET_PERSISTANT_FILTERS(currentState, { listName, persistantFilters }) {
    currentState[listName].persistantFilters = persistantFilters
  },
  SET_IS_LOADING(currentState, { listName, isLoading }) {
    currentState[listName].isLoading = isLoading
  },
  SET_HAS_FILTERS_OR_QUERY_APPLIED(currentState, { listName, hasFiltersOrQueryApplied }) {
    currentState[listName].hasFiltersOrQueryApplied = hasFiltersOrQueryApplied
  },
  SET_TOTAL_ITEMS(currentState, { listName, totalItems }) {
    currentState[listName].totalItems = totalItems
  },
  SET_TOTAL_PAGES(currentState, { listName, totalPages }) {
    currentState[listName].totalPages = totalPages
  },
  SET_CURRENT_PAGE(currentState, { listName, currentPage }) {
    currentState[listName].currentPage = currentPage
  },
  SET_CURRENT_PAGE_NUMBER(currentState, { listName, pageNumber }) {
    currentState[listName].currentPageNumber = pageNumber
  },
  SET_PAGE_SIZE(currentState, { listName, pageSize }) {
    currentState[listName].currentPageNumber = pageSize
  },
  SET_ITEM_IN_CURRENT_PAGE(currentState, { listName, newItem }) {

    const existingIndex = currentState[listName].currentPage.findIndex((item) => {
      return item.id === newItem.id
    })

    if (existingIndex !== -1) {
      currentState[listName].currentPage[existingIndex] = newItem
    }

  },
}
