<template>
  <div class="toast-container">
    <div
      :key="index"
      class="alert toast"
      v-for="(toast, index) in toasts"
      @click="typeof toast.clickHandler === 'function' ? toast.clickHandler.call(toast, toast) : undefined"
      :class="[
        toast.type,
        typeof toast.clickHandler === 'function' ? 'clickable' : undefined
      ]"
    >
      <span class="toast-text">{{ toast.text }}</span>
      <button title="close" class="close link gray" @click.stop="$store.commit('toast/REMOVE_TOAST', toast)">
        <CloseIcon />
      </button>
    </div>
  </div>
</template>

<script>

  import { mapState } from 'vuex'

  import CloseIcon from '@/assets/icons/close.svg'

  export default {
    components: {
      CloseIcon,
    },
    computed: {
      ...mapState('toast', ['toasts']),
    },
  }

</script>

<style lang="stylus" scoped>

  .toast-container
    @apply w-full
    @apply h-full
    @apply space-y-2

    @apply z-30
    @apply fixed
    @apply top-0
    @apply right-0

    @apply p-4
    @apply overflow-y-hidden
    @apply pointer-events-none

    +breakpoint(sm)
      @apply w-96

    .toast
      @apply z-30
      @apply top-0
      @apply right-0
      @apply relative

      @apply shadow-2xl
      @apply pointer-events-auto

      @apply flex
      @apply items-start
      @apply justify-between

      animation: .25s ease-out 0s slide-in

      &.clickable
        @apply cursor-pointer

      .toast-text
        @apply break-words
        @apply whitespace-pre-wrap

        max-width: calc(100% - 2rem)

      .close
        @apply w-4
        @apply h-4

        min-width: theme('width.4')
        min-height: theme('height.4')

        svg
          @apply w-full
          @apply h-full

  @keyframes slide-in
    from
      @apply -right-full

    to
      @apply right-0

</style>
