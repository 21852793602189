export const sortByKey = (keyName) => {
  return (a, b) => {
    return a[keyName].toLowerCase() < b[keyName].toLowerCase() ? -1 : 1
  }
}

export const sortByName = sortByKey('name')

export default {
  sortByKey,
  sortByName,
}
